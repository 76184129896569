import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { UsersService } from '../../../../../../../auth/src/lib/services/users.service';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UserActions } from '../actions/users.actions';
import { User } from '../../../../../../../auth/src/lib/interfaces/user.interface';
import { Store } from '@ngrx/store';
import { usersFeature } from '../features';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { UserAddEditComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/user-add-edit/user-add-edit.component';
import { ConstantService } from '../../../constant.service';
import { Constant } from 'libs/services/src/lib/services/shared/constant.interface';
import { DialogOptions } from '../../../config/dialog-options';
import { ApplicationRole } from '../../../../../../../../libs/auth/src/lib/interfaces/application-role.interface';
import { OperatorActions } from '../actions/operators.actions';
import { LocationActions } from '../actions/locations.actions';
import { Router } from '@angular/router';

export const loadUsersPage = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(UserActions.load_Users_Page),
      switchMap(() =>
        of(
          UserActions.load_Users(),
          UserActions.load_User_Roles(),
          OperatorActions.load_Operators(),
          UserActions.load_User_Application_Roles(),
          LocationActions.load_Locations()
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadUsers = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    usersService = inject(UsersService)
  ) => {
    return actions.pipe(
      ofType(
        UserActions.load_Users,
        UserActions.update_User_Queries,
        UserActions.remove_User_Success,
        UserActions.add_User_Success,
        UserActions.edit_User_Success
      ),
      concatLatestFrom(() => store.select(usersFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap(() =>
        usersService.loadUsers().pipe(
          map((res: User[]) => UserActions.load_Users_Success({ users: res })),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.load_Users_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadUserRoles = createEffect(
  (actions = inject(Actions), constantService = inject(ConstantService)) => {
    return actions.pipe(
      ofType(UserActions.load_User_Roles),
      mergeMap(() =>
        constantService.loadUserRoles().pipe(
          map((res: Constant[]) =>
            UserActions.load_User_Roles_Success({ userRoles: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.load_User_Roles_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadApplicationRoles = createEffect(
  (actions = inject(Actions), constantService = inject(ConstantService)) => {
    return actions.pipe(
      ofType(UserActions.load_User_Application_Roles),
      mergeMap(() =>
        constantService.loadApplicationRoles().pipe(
          map((res: ApplicationRole[]) =>
            UserActions.load_User_Application_Roles_Success({
              incomingDataApplicationRoles: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.load_User_Application_Roles_Failure({ error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const removeUser = createEffect(
  (actions = inject(Actions), usersService = inject(UsersService)) => {
    return actions.pipe(
      ofType(UserActions.remove_User),
      mergeMap((action) =>
        usersService.removeUser(action.id).pipe(
          map((res: User) =>
            UserActions.remove_User_Success({
              user: res,
              successMessage: 'User remove successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.remove_User_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addUser = createEffect(
  (actions = inject(Actions), usersService = inject(UsersService)) => {
    return actions.pipe(
      ofType(UserActions.add_User),
      mergeMap((action) =>
        usersService.addUser(action.user).pipe(
          map((res: User) =>
            UserActions.add_User_Success({
              user: res,
              successMessage: 'User added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.add_User_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editUser = createEffect(
  (actions = inject(Actions), usersService = inject(UsersService)) => {
    return actions.pipe(
      ofType(UserActions.edit_User),
      mergeMap((action) =>
        usersService.editUser(action.userId, action.user).pipe(
          map((res: User) =>
            UserActions.edit_User_Success({
              user: res,
              successMessage: 'User edited successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.edit_User_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editUserLocation = createEffect(
  (actions = inject(Actions), usersService = inject(UsersService)) => {
    return actions.pipe(
      ofType(UserActions.edit_User_Location),
      mergeMap((action) =>
        usersService.editUserDetails(action.userId, action.user).pipe(
          map((res: User) =>
            UserActions.edit_User_Location_Success({
              user: res,
              successMessage:
                'Location updated successfully! You may have been relocated to update content',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.edit_User_Location_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editUserLocationSuccess = createEffect(
  (actions = inject(Actions), router = inject(Router)) => {
    return actions.pipe(
      ofType(UserActions.edit_User_Location_Success),
      tap(() => {
        router.navigate(['/']).then(() => {
          window.location.reload();
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

export const editUserDetails = createEffect(
  (actions = inject(Actions), usersService = inject(UsersService)) => {
    return actions.pipe(
      ofType(UserActions.edit_User_Details),
      mergeMap((action) =>
        usersService.editUserDetails(action.userId, action.user).pipe(
          map((res: User) =>
            UserActions.edit_User_Success({
              user: res,
              successMessage: 'User edited successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.edit_User_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const openUserDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(UserActions.open_User_Dialog),
      tap((action) => {
        dialog.open(UserAddEditComponent, {
          ...dialogOptions,
          data: {
            user: action.user,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);
