import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AreaActions } from '../actions/area.actions';
import { catchError, map, mergeMap, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { SiteService } from '../../site.service';
import { SiteActions } from '../actions/site.actions';
import { Site } from '../../interfaces/site.interface';
import { siteFeature } from '../features/site.features';

export const loadSites = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    siteService = inject(SiteService)
  ) => {
    return actions.pipe(
      ofType(SiteActions.set_Site_Id, SiteActions.load_Sites),
      concatLatestFrom(() => store.select(siteFeature.selectSiteId)),
      map(([, res]) => res),
      filter((res) => !!res),
      mergeMap((action) =>
        siteService.loadSitesByLocationId(action).pipe(
          map((res: Site[]) =>
            SiteActions.load_Sites_Success({
              sites: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              AreaActions.load_Areas_Failure({
                error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadSiteByLocationId = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) => {
    return actions$.pipe(
      ofType(SiteActions.load_Sites_By_Location_Id),
      mergeMap((action) =>
        siteService.loadSitesByLocationId(action.id).pipe(
          map((sites: Site[]) =>
            SiteActions.load_Sites_By_Location_Id_Success({
              sites,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(SiteActions.load_Sites_By_Location_Id_Failure({ error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const removeSite = createEffect(
  (
    actions = inject(Actions),
    siteService = inject(SiteService),
    store = inject(Store)
  ) => {
    return actions.pipe(
      ofType(SiteActions.remove_Site),
      mergeMap((action) =>
        siteService.removeSite(action.id).pipe(
          mergeMap((res: Site) => {
            return [
              SiteActions.remove_Site_Success({
                site: res,
                successMessage: 'Site removed successfully!',
              }),
              SiteActions.load_Sites_By_Location_Id({ id: action.locationId }),
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(SiteActions.remove_Site_Failure({ error: error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const addSite = createEffect(
  (actions = inject(Actions), siteService = inject(SiteService)) => {
    return actions.pipe(
      ofType(SiteActions.add_Site),
      mergeMap((action) =>
        siteService.addSite(action.site).pipe(
          mergeMap((res: Site) => {
            return [
              SiteActions.add_Site_Success({
                site: res,
                successMessage: 'Site added successfully!',
              }),
              SiteActions.load_Sites_By_Location_Id({ id: res.locationId }),
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(SiteActions.add_Site_Failure({ error: error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const editSite = createEffect(
  (actions = inject(Actions), siteService = inject(SiteService)) => {
    return actions.pipe(
      ofType(SiteActions.edit_Site),
      mergeMap((action) =>
        siteService.editSite(action.id, action.site).pipe(
          mergeMap((res: Site) => {
            return [
              SiteActions.edit_Site_Success({
                site: res,
                successMessage: 'Site edited successfully!',
              }),
              SiteActions.load_Sites_By_Location_Id({ id: res.locationId }),
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(SiteActions.edit_Site_Failure({ error: error }))
          )
        )
      )
    );
  },
  { functional: true }
);
