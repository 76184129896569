import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of } from 'rxjs';
import { AreaActions } from '../actions/area.actions';
import { filter, switchMap } from 'rxjs/operators';
import { Area } from '../../interfaces/area.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { AreaService } from '../../area.service';
import { areaFeature } from '../features/area.features';
import { BlockingActivityActions } from '../actions/blocking-activity.actions';

export const loadAreasOnDialogVisible = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(AreaActions.is_Area_Dialog_Visible),
      filter(({ isVisible }) => isVisible),
      switchMap(({ site }) =>
        of(
          AreaActions.load_Areas_By_Site_Id({ id: site.siteId }),
          BlockingActivityActions.load_Blocking_Activities()
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadAreas = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    areaService = inject(AreaService)
  ) => {
    return actions.pipe(
      ofType(AreaActions.set_Area_Id, AreaActions.load_Areas),
      concatLatestFrom(() => store.select(areaFeature.selectAreaId)),
      map(([, res]) => res),
      filter((res) => !!res),
      mergeMap((action) =>
        areaService.loadAreasBySiteId(action).pipe(
          map((res: Area[]) =>
            AreaActions.load_Areas_Success({
              areas: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              AreaActions.load_Areas_Failure({
                error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadAreaBySiteId = createEffect(
  (actions$ = inject(Actions), areaService = inject(AreaService)) => {
    return actions$.pipe(
      ofType(AreaActions.load_Areas_By_Site_Id),
      mergeMap((action) =>
        areaService.loadAreasBySiteId(action.id).pipe(
          map((areas: Area[]) =>
            AreaActions.load_Areas_By_Site_Id_Success({
              areas,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(AreaActions.load_Areas_By_Site_Id_Failure({ error }))
          )
        )
      )
    );
  },
  { functional: true }
);

export const removeArea = createEffect(
  (actions = inject(Actions), areaService = inject(AreaService)) => {
    return actions.pipe(
      ofType(AreaActions.remove_Area),
      mergeMap((action) =>
        areaService.removeArea(action.id).pipe(
          mergeMap((res: Area) => {
            return [
              AreaActions.remove_Area_Success({
                area: res,
                successMessage: 'Area removed successfully!',
              }),
              AreaActions.load_Areas_By_Site_Id({ id: action.siteId }),
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              AreaActions.remove_Area_Failure({
                error: error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addArea = createEffect(
  (actions = inject(Actions), areaService = inject(AreaService)) => {
    return actions.pipe(
      ofType(AreaActions.add_Area),
      mergeMap((action) =>
        areaService.addArea(action.area).pipe(
          mergeMap((res: Area) => {
            return [
              AreaActions.add_Area_Success({
                area: res,
                successMessage: 'Area added successfully!',
              }),
              AreaActions.load_Areas_By_Site_Id({ id: res.siteId }),
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              AreaActions.add_Area_Failure({
                error: error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editArea = createEffect(
  (actions = inject(Actions), areaService = inject(AreaService)) => {
    return actions.pipe(
      ofType(AreaActions.edit_Area),
      mergeMap((action) =>
        areaService.editArea(action.id, action.area).pipe(
          mergeMap((res: Area) => {
            return [
              AreaActions.edit_Area_Success({
                area: res,
                successMessage: 'Area edited successfully!',
              }),
              AreaActions.load_Areas_By_Site_Id({ id: res.siteId }),
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              AreaActions.edit_Area_Failure({
                error: error,
              })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadAreaByLocationId = createEffect(
  (actions = inject(Actions), areaService = inject(AreaService)) => {
    return actions.pipe(
      ofType(AreaActions.load_Areas_By_Location_Id),
      switchMap(({ locationId }) => {
        return areaService.loadAreasByLocationId(locationId).pipe(
          map((areas: Area[]) =>
            AreaActions.load_Areas_By_Location_Id_Success({ areas })
          ),
          catchError((error: HttpErrorResponse) =>
            of(AreaActions.load_Areas_By_Location_Id_Failure({ error }))
          )
        );
      })
    );
  },
  { functional: true }
);
