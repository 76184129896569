import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from 'libs/components/src/lib/functions/utility.functions';
import { Pool } from '../../interfaces/pool.interface';
import { SearchQuery } from 'libs/components/src/lib/interfaces/search-query.interface';

export const PoolsActions = createActionGroup({
  source: '[Pool]',
  events: {
    update_Pools_Queries: props<{ query: SearchQuery }>(),

    Load_Pools_Page: emptyProps(),
    Load_Pools: emptyProps(),
    Load_Pools_Success: props<{ poolList: Pool[] }>(),
    Load_Pools_Failure: errorProps(),

    load_Pools_By_Id: props<{ poolId: string }>(),
    load_Pools_By_Id_Success: props<{ poolList: Pool[] }>(),
    load_Pools_By_Id_Error: errorProps(),

    add_Pool: props<{ pool: Pool }>(),
    add_Pool_Success: props<{
        pool: Pool;
      successMessage: string;
    }>(),
    add_Pool_Failure: errorProps(),

    edit_Pool: props<{ poolId: string; pool: Pool }>(),
    edit_Pool_Success: props<{
      pool: Pool;
      successMessage: string;
    }>(),
    edit_Pool_Failure: errorProps(),

    open_Pool_Dialog: props<{ pool: Pool | null }>(),

    load_Pools_By_Location_Id: props<{ locationId: string }>(),
    load_Pools_By_Location_Id_Success: props<{ pools: Pool[] }>(),
    load_Pools_By_Location_Id_Error: errorProps(),

    revert_Pool_State: props<{ poolId: string, pool: Pool }>(),
  },
});