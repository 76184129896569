import { District } from '../../interfaces/district.interfance';
import { MaintenanceState } from '../../interfaces/maintenance-state.interface';
import { maintenanceInitialState } from './maintenance.state';

export interface DistrictState extends MaintenanceState {
  districts: District[];
  districtId: string
}

export const initializeDistrictState: DistrictState = {
  districts: [],
  districtId: '',
  ...maintenanceInitialState,
}
