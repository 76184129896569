<div class="toolbar">
  <div class="title-container flex-1 fs-20">
    <a href="{{ url }}"> Lighthouse </a>
    <a [routerLink]="['/']" *ngIf="title">
      <i class="pi pi-chevron-right fs-14"></i>
      {{ title }}
    </a>
  </div>
  <ng-template class="header-info" [cdkPortalOutlet]="portal()"></ng-template>
  <div class="d-flex justify-content-end align-items-center gap-8 flex-2">
    <div class="fs-13 text-capitalize">
      {{ tenantName }}
    </div>
    <button
      type="button"
      class="d-flex align-items-center gap-24 btn-text-white"
      (click)="locations.toggle($event)"
    >
      <span class="f-bold fs-13">
        {{
          clientLocations().length
            ? (clientLocations()
                | filterBy
                  : byLocationId(currentUserState().user.locationId))[0]
                .locationName
            : ''
        }}
      </span>
      <i class="pi pi-angle-down"></i>
    </button>

    <div class="d-flex align-items-center gap-16">
      <div class="fs-24 f-normal d-flex align-items-center">|</div>

      <i
        class="pi pi-bell"
        pTooltip="Notifications"
        tooltipPosition="bottom"
      ></i>

      <i
        class="pi pi-question-circle"
        pTooltip="Help"
        tooltipPosition="bottom"
      ></i>

      <i
        class="pi pi-user cursor-pointer"
        pTooltip="Profile"
        tooltipPosition="bottom"
        (click)="profile.toggle($event)"
      ></i>

      <i class="pi pi-th-large cursor-pointer" (click)="app.toggle($event)"></i>
    </div>
  </div>
</div>

<p-menu #locations [model]="locationsItems()" [popup]="true" />

<p-menu #profile [model]="menuProfile" [popup]="true" />

<p-menu #app [model]="menuApp()" [popup]="true" [style]="{ width: '250px' }">
  <ng-template pTemplate="start">
    <span class="p-16 d-flex fs-18">Switch to...</span></ng-template
  >
  <ng-template pTemplate="item" let-item>
    <a [href]="item.url" class="p-menuitem-link align-items-center">
      <img [src]="item.icon" />
      <div class="d-flex flex-direction-column">
        <span class="fs-18 f-bold">{{ item.label }}</span>
        <span class="fs-12">{{ item.title }}</span>
      </div>
    </a>
  </ng-template>
</p-menu>
