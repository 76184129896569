import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';
import { TransportRequestCargo } from '../../interfaces/transport-request-cargo.interface';
import { TransportRequestCargoFilterList } from '../../interfaces/transport-request-cargo-filter-list.interface';
import { TransportRequestCargoSnapshot } from '../../interfaces/transport-request-cargo-snapshot.interface';
import { TransportRequestCargoAttachment } from '../../interfaces/transport-request-cargo-attachment.interface';
import { TransportRequestCargoApprovalFilter } from '../../interfaces/transport-request-cargo-approval-filter.interface';

export const TransportRequestCargoActions = createActionGroup({
  source: 'TransportRequestCargos',
  events: {
    load_Transport_Requests_Cargos_Create_Edit: props<{
      locationId: string;
      transportRequestId: string;
    }>(),
    load_Transport_Requests_Cargos: props<{
      transportRequestId: string;
    }>(),
    load_Transport_Requests_Cargos_Success: props<{
      transportRequestCargos: TransportRequestCargo[];
      locationId: string;
    }>(),
    load_Transport_Requests_Cargos_Failure: errorProps(),
    load_Transport_Requests_Cargo: props<{
      transportRequestCargoId: string;
    }>(),
    load_Transport_Requests_Cargo_Success: props<{
      transportRequestCargo: TransportRequestCargo;
    }>(),
    load_Transport_Requests_Cargo_Failure: errorProps(),
    update_Transport_Request_Cargo_Version_Index: props<{
      transportRequestCargoSnapshotId: string;
      selectedIndex: number;
    }>(),
    load_Transport_Request_Cargo_Version: props<{
      transportRequestCargoSnapshotId: string;
    }>(),
    load_Transport_Request_Cargo_Version_Success: props<{
      transportRequestCargoSnapshot: TransportRequestCargoSnapshot;
    }>(),
    load_Transport_Request_Cargo_Version_Failure: errorProps(),
    remove_Transport_Requests_Cargo: props<{
      transportRequestCargoIds: string[];
      transportRequestId: string;
    }>(),
    remove_Transport_Requests_Cargo_Success: props<{
      transportRequestId: string;
      successMessage: string;
    }>(),
    remove_Transport_Requests_Cargo_Failure: errorProps(),
    add_Transport_Requests_Cargo: props<{
      transportRequestCargo: TransportRequestCargo;
      transportRequestId: string;
    }>(),
    add_Transport_Requests_Cargo_Success: props<{
      transportRequestCargo: TransportRequestCargo;
      transportRequestId: string;
      successMessage: string;
    }>(),
    add_Transport_Requests_Cargo_Failure: errorProps(),

    bulk_update_Transport_Requests_Cargo: props<{
      transportRequestCargoes: TransportRequestCargo[];
      transportRequestId: string;
    }>(),
    bulk_update_Transport_Requests_Cargo_Success: props<{
      transportRequestId: string;
      successMessage: string;
    }>(),
    bulk_update_Transport_Requests_Cargo_Failure: errorProps(),

    edit_Transport_Requests_Cargo: props<{
      transportRequestCargoId: string;
      transportRequestId: string;
      transportRequestCargo: TransportRequestCargo;
    }>(),
    edit_Transport_Requests_Cargo_Success: props<{
      transportRequestCargo: TransportRequestCargo;
      transportRequestId: string;
      successMessage: string;
    }>(),
    edit_Transport_Requests_Cargo_Failure: errorProps(),

    update_Transport_Request_Cargo: props<{
      locationId: string,
      transportRequestCargoId: string,
      transportRequestCargo: TransportRequestCargo
    }>(),
    update_Transport_Request_Cargo_Success: props<{
      transportRequestCargo: TransportRequestCargo,
      transportRequestId: string,
      locationId: string,
      successMessage: string;
      filterModel?: TransportRequestCargoApprovalFilter;
    }>(),
    update_Transport_Request_Cargo_Failure: errorProps(),

    filter_Transport_Request_Cargo_List: props<{
      filter: TransportRequestCargoFilterList;
    }>(),
    cancel_Transport_Request_Cargos: props<{
      transportRequestCargoIds: string[];
      transportRequestId: string;
      cancellationReason: string;
    }>(),
    cancel_Transport_Request_Cargos_Success: props<{
      transportRequestId: string;
      successMessage: string;
    }>(),
    cancel_Transport_Request_Cargos_Failure: errorProps(),
    select_items: props<{ selectedItems: TransportRequestCargo[] }>(),
    delete_comment: props<{ transportRequestCargoId: string }>(),
    delete_comment_success: props<{ successMessage: string }>(),
    delete_comment_failure: errorProps(),
    add_comment: props<{ comment: string, transportRequestCargoId: string }>(),
    add_comment_success: props<{ successMessage: string }>(),
    add_comment_failure: errorProps(),
    load_Transport_Request_Cargo_Attachments: props<{
      transportRequestCargoId: string | null;
    }>(),
    load_Files_Success: props<{ files: TransportRequestCargoAttachment[] }>(),
    upload_Files: props<{
      transportRequestId: string;
      blobIds: string[];
      transportRequestCargoId: string | null;
    }>(),
    upload_Files_Success: props<{
      transportRequestCargoId: string;
      successMessage: string;
    }>(),

    download_Attachment: props<{
      blobId: string;
      transportRequestCargoId: string;
    }>(),
    download_Attachment_Success: emptyProps(),
    remove_Attachment: props<{
      blobIds: string[];
      transportRequestCargoId: string;
    }>(),
    remove_Attachment_Success: props<{
      transportRequestCargoId: string;
      successMessage: string;
    }>(),
    remove_Attachment_Failure: errorProps(),
    upload_Files_Failure: errorProps(),
    selected_Version_Index: props<{ selectedIndex: number }>(),
    load_All_Cargo_Attachments: props<{
      transportRequestId: string;
      transportRequestCargoId: string;
    }>(),
    reinstate_transport_request_cargos: props<{
      transportRequestCargoIds: string[];
      transportRequestId: string;
    }>(),
    reinstate_transport_request_cargos_success: props<{
      transportRequestId: string;
      successMessage: string;
    }>(),
    reinstate_transport_request_cargos_failure: errorProps(),
    export_Cargo_Table: props<{ transportRequestId: string }>(),
    export_Cargo_Table_Success: emptyProps(),
    export_Cargo_Table_Failure: errorProps(),
    load_Transport_Requests_Cargo_Approvals: props<{
      locationId: string;
      filterModel?: TransportRequestCargoApprovalFilter;
    }>(),
    load_Transport_Requests_Cargo_Approvals_Success: props<{
      transportRequestCargos: TransportRequestCargo[];
    }>(),
    load_Transport_Requests_Cargo_Approvals_Failure: errorProps(),
    update_Cargo_Approval_Page_Status: props<{ status: number; }>(),
    manual_add_item: emptyProps(),
  },
});
