import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { AssetActions } from '../actions/assets.actions';
import { assetsInitialState } from '../states/assets.state';
import { Asset } from '../../interfaces/asset.interface';

export const assetReducer = createReducer(
  assetsInitialState,
  immerOn(AssetActions.remove_Asset, AssetActions.load_Assets, (state) => {
    state.loading.list = true;
  }),
  immerOn(AssetActions.load_Cluster_Heads, (state) => {
    state.loading.loadHead = true;
  }),
  immerOn(AssetActions.load_Cluster_Heads_Success, (state, { assetsHead }) => {
    state.assetsHead = assetsHead;
    state.loading.loadHead = false;
  }),
  immerOn(AssetActions.load_Cluster_Heads_Failure, (state) => {
    state.loading.loadHead = false;
  }),
  immerOn(AssetActions.update_Asset_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),
  immerOn(AssetActions.load_Assets_By_Location_Success, (state, payload) => {
    state.assets = payload.assets
  }),

  immerOn(AssetActions.export_Assets, (state) => {
    state.loading.export = true;
  }),
  immerOn(
    AssetActions.export_Assets_Success,
    AssetActions.export_Assets_Failure,
    (state) => {
      state.loading.export = false;
    }
  ),
  immerOn(AssetActions.load_Assets_Success, (state, { assets }) => {
    state.assets = assets;
    state.loading.list = false;
  }),
  immerOn(AssetActions.load_Asset_Types_Success, (state, { assetTypes }) => {
    state.assetTypesList = assetTypes;
  }),
  immerOn(
    AssetActions.load_Assets_Failure,
    AssetActions.remove_Asset_Success,
    AssetActions.remove_Asset_Failure,
    AssetActions.load_Asset_Types_Success,
    AssetActions.load_Asset_Types_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(AssetActions.add_Asset, AssetActions.edit_Asset, (state) => {
    state.loading.createEdit = true;
  }),
  immerOn(
    AssetActions.add_Asset_Success,
    AssetActions.add_Asset_Failure,
    AssetActions.edit_Asset_Success,
    AssetActions.edit_Asset_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  ),
);

export const assetsFeature = createFeature({
  name: 'assets',
  reducer: assetReducer,
  extraSelectors: ({ selectLoading, selectAssetsHead, selectAssets }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
    selectFilterAssetsHead: (id: string) =>
      createSelector(selectAssetsHead, (selectAssetsHead) =>
        selectAssetsHead.filter((item) => item.assetId !== id)
      ),
    selectAssetsForDistances: createSelector(selectAssets, (selectAssets) =>
      selectAssets.filter((item: Asset) => item.assetType !== 'MOB')
    ),
  }),
});
