import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivityCategoryType } from './interfaces/activity-category-type.interface';
import { ActivityCategory } from './interfaces/activity-category.interface';

@Injectable({ providedIn: 'root' })
export class ActivityCategoryTypeService {
  private readonly http = inject(HttpClient);

  loadCategoryTypes(): Observable<ActivityCategoryType[]> {
    return this.http.get<ActivityCategoryType[]>('/api/activityCategoryType');
  }

  loadActivityCategoryTypes(id: string): Observable<ActivityCategoryType[]> {
    return this.http.get<ActivityCategoryType[]>(
      `/api/activityCategoryType/${id}`
    );
  }

  loadActivityCategoryTypesByCategoryId(
    id: string
  ): Observable<ActivityCategoryType[]> {
    return this.http.get<ActivityCategoryType[]>(
      `/api/activityCategoryType/byCategoryId/${id}`
    );
  }

  removeActivityCategoryType(id: string): Observable<ActivityCategoryType> {
    return this.http.delete<ActivityCategoryType>(
      `/api/activityCategoryType/${id}`
    );
  }

  addActivityCategoryType(
    activityCategoryType: ActivityCategoryType
  ): Observable<ActivityCategoryType> {
    return this.http.post<ActivityCategoryType>(
      '/api/activityCategoryType',
      activityCategoryType
    );
  }

  editActivityCategoryType(
    id: string,
    activityCategoryType: ActivityCategoryType
  ): Observable<ActivityCategoryType> {
    return this.http.put<ActivityCategoryType>(
      `/api/activityCategoryType/${id}`,
      activityCategoryType
    );
  }
}
