import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  snackBar = inject(MatSnackBar);

  handleError(error: {
    error: HttpErrorResponse;
    customMessage?: string;
  }): void {
    this.checkErrorStatus(error);
  }

  private checkErrorStatus(error: {
    error: HttpErrorResponse;
    customMessage?: string;
  }): void {
    let message = null;

    if (error.error.error instanceof ArrayBuffer) {
      // Decode ArrayBuffer to string
      const decoder = new TextDecoder('utf-8');
      const decodedMessage = decoder.decode(error.error.error);
      try {
        // Try to parse the decoded message as JSON
        const errorObj = JSON.parse(decodedMessage);
        // Extract message from your error object structure
        message = errorObj.details || 'An error occurred';
      } catch (e) {
        // If it's not JSON, use the decoded string directly
        message = decodedMessage;
      }
    } else {
      // Your existing status code-based error handling
      switch (error.error.status) {
        case 400:
          message = error.error.error;
          break;
        case 500:
          message = error.error.error.details;
          break;
      }
    }

    if (error.customMessage) {
      message = error.customMessage;
    }
    this.snackBar.open(
      message || 'An error happened. Please try again later',
      'Close',
      {
        duration: 10000,
        panelClass: 'snackbar--error',
      }
    );
  }
}
