import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'showLoading'
})
export class ShowLoadingPipe implements PipeTransform {

  transform(value: any): boolean {
    const values = Object.values(value);
    return values.some(el => el === true);
  }

}
