import { inject, Injectable } from '@angular/core';
import { SearchQuery } from '../../../../../components/src/lib/interfaces/search-query.interface';
import { Observable } from 'rxjs';
import {
  Distance,
  DistancePageTableData,
} from './interfaces/distance.interface';
import { Operator } from './interfaces/operator.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class DistanceService {
  private readonly http = inject(HttpClient);

  loadDistances(query: SearchQuery): Observable<DistancePageTableData> {
    const params = Object.assign({}, query) as Partial<SearchQuery>;
    Object.keys(params).forEach((k: string) => {
      const key = k as keyof SearchQuery;
      if (!params[key] && params[key] !== 0) {
        delete params[key];
      }
    });
    return this.http.get<DistancePageTableData>('/api/distance/getPage', {
      params,
    });
  }

  removeDistance(id: string): Observable<Distance> {
    return this.http.delete<Distance>(`/api/distance/${id}`);
  }

  addDistance(distance: Distance): Observable<Distance> {
    return this.http.post<Distance>('/api/distance', distance);
  }

  editDistance(id: string, distance: Distance): Observable<Distance> {
    return this.http.put<Distance>(`/api/distance/${id}`, distance);
  }

  exportDistances(): Observable<ArrayBuffer> {
    return this.http.get('/api/distance/exportdistances', {
      responseType: 'arraybuffer',
    });
  }
}
