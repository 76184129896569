import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { Area } from '../../interfaces/area.interface';
import { errorProps } from '../../../../../../../auth/src/lib/store/auth.actions';
import { Site } from '../../interfaces/site.interface';
import { Location } from '../../interfaces/location.interface';

export const AreaActions = createActionGroup({
  source: 'Area',
  events: {
    update_Area_Queries: props<{ query: SearchQuery }>(),
    set_Area_Id: props<{ id: string }>(),
    open_Area_Dialog: props<{ id: string; site: Site }>(),
    load_Areas: emptyProps(),
    load_Areas_Success: props<{
      areas: Area[];
    }>(),
    load_Areas_Failure: errorProps(),
    remove_Area: props<{ id: string; siteId: string }>(),
    remove_Area_Success: props<{ area: Area; successMessage: string }>(),
    remove_Area_Failure: errorProps(),
    add_Area: props<{ area: Area }>(),
    add_Area_Success: props<{ area: Area; successMessage: string }>(),
    add_Area_Failure: errorProps(),
    edit_Area: props<{ id: string; area: Area }>(),
    edit_Area_Success: props<{ area: Area; successMessage: string }>(),
    edit_Area_Failure: errorProps(),
    add_Area_Row: emptyProps(),
    remove_Area_Row: emptyProps(),
    load_Areas_By_Site_Id: props<{ id: string }>(),
    load_Areas_By_Site_Id_Success: props<{
      areas: Area[];
    }>(),
    load_Areas_By_Site_Id_Failure: errorProps(),

    load_Areas_By_Location_Id: props<{ locationId: string }>(),
    load_Areas_By_Location_Id_Success: props<{
      areas: Area[];
    }>(),
    load_Areas_By_Location_Id_Failure: errorProps(),
    is_Area_Dialog_Visible: props<{
      isVisible: boolean;
      site: Site;
    }>(),
  },
});
