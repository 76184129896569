import { inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { VendorsActions } from '../actions/vendor.actions';
import { VendorService } from '../../vendor.service';
import { Vendor } from '../../interfaces/vendor.interface';
import { LocationActions } from '../actions/locations.actions';
import { MatDialog } from '@angular/material/dialog';
import { DialogOptions } from '../../../config/dialog-options';
import { VendorsAddEditComponent } from '../../../../../../../components/src/lib/components/vendors-add-edit/vendors-add-edit.component';

export const loadVendorsPage = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(VendorsActions.load_Vendors_Page),
      switchMap(() =>
        of(VendorsActions.load_Vendors(), LocationActions.load_Locations())
      )
    );
  },
  {
    functional: true,
  }
);

export const loadVendors = createEffect(
  (actions = inject(Actions), service = inject(VendorService)) => {
    return actions.pipe(
      ofType(VendorsActions.load_Vendors),
      switchMap(() =>
        service.loadVendorsList().pipe(
          map((vendorList) =>
            VendorsActions.load_Vendors_Success({
              vendorList,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(VendorsActions.load_Vendors_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadVendorsByLocation = createEffect(
  (actions = inject(Actions), service = inject(VendorService)) => {
    return actions.pipe(
      ofType(VendorsActions.load_Vendors_By_Location_Id),
      switchMap(({ locationId }) =>
        service.loadVendorsByLocationId(locationId).pipe(
          map((vendors) =>
            VendorsActions.load_Vendors_By_Location_Id_Success({
              vendors,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(VendorsActions.load_Vendors_By_Location_Id_Error({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const load_Vendor_Addresses_By_Voyage_Id = createEffect(
  (actions = inject(Actions), service = inject(VendorService)) => {
    return actions.pipe(
      ofType(VendorsActions.load_Vendor_Addresses_By_Voyage_Id),
      switchMap(({ voyageId }) =>
        service.loadVendorAddressesByVoyageId(voyageId).pipe(
          map((addressesList) =>
            VendorsActions.load_Vendor_Addresses_By_Voyage_Id_Success({
              addressesList,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              VendorsActions.load_Vendor_Addresses_By_Voyage_Id_Error({ error })
            )
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const load_Vendors_By_Voyage_Id = createEffect(
  (actions = inject(Actions), service = inject(VendorService)) => {
    return actions.pipe(
      ofType(VendorsActions.load_Vendors_By_Voyage_Id),
      switchMap(({ voyageId }) =>
        service.loadVendorsByVoyageId(voyageId).pipe(
          map((vendorList) =>
            VendorsActions.load_Vendors_By_Voyage_Id_Success({
              vendorList,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(VendorsActions.load_Vendors_By_Voyage_Id_Error({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addVendor = createEffect(
  (actions = inject(Actions), service = inject(VendorService)) => {
    return actions.pipe(
      ofType(VendorsActions.add_Vendor),
      switchMap(({ vendor }) =>
        service.createVendor(vendor).pipe(
          map((vendor) =>
            VendorsActions.add_Vendor_Success({
              vendor: vendor,
              successMessage: 'Vendor Added Successfully',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(VendorsActions.add_Vendor_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editVendor = createEffect(
  (actions = inject(Actions), service = inject(VendorService)) => {
    return actions.pipe(
      ofType(VendorsActions.edit_Vendor),
      switchMap(({ vendorId, vendor }) =>
        service.updateVendor(vendorId, vendor).pipe(
          map((res: Vendor) =>
            VendorsActions.edit_Vendor_Success({
              vendor: res,
              successMessage: 'Vendor Edited Successfully',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(VendorsActions.edit_Vendor_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const deleteVendors = createEffect(
  (actions = inject(Actions), service = inject(VendorService)) => {
    return actions.pipe(
      ofType(VendorsActions.remove_Vendor),
      switchMap(({ vendorId }) =>
        service.deleteVendor(vendorId).pipe(
          switchMap(() => [
            VendorsActions.remove_Vendor_Success({
              successMessage: 'Vendor deleted Successfully',
            }),
          ]),
          catchError((error: HttpErrorResponse) =>
            of(VendorsActions.remove_Vendor_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const AddEditRemoveVendorsSuccess = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        VendorsActions.add_Vendor_Success,
        VendorsActions.edit_Vendor_Success,
        VendorsActions.remove_Vendor_Success
      ),
      switchMap(() => of(VendorsActions.load_Vendors()))
    );
  },
  {
    functional: true,
  }
);

export const openVendorsDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(VendorsActions.open_Vendor_Dialog),
      tap((action) => {
        dialog.open(VendorsAddEditComponent, {
          ...dialogOptions,
          data: {
            vendor: action.vendor,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);
