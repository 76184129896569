import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { cargoesInitialState } from '../states/cargoes.state';
import { CargoesActions } from '../actions/cargoes.actions';

export const cargoesReducer = createReducer(
  cargoesInitialState,
  immerOn(CargoesActions.load_Cargos, CargoesActions.remove_Cargo, (state) => {
    state.loading.list = true;
  }),
  immerOn(CargoesActions.update_Cargo_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),
  immerOn(
    CargoesActions.load_Cargo_Descriptions_Success,
    (state, { cargoDescriptions }) => {
      state.cargoDescriptions = cargoDescriptions;
    }
  ),
  immerOn(CargoesActions.load_Cargos_Success, (state, { Cargos }) => {
    state.cargoes = Cargos;
    state.loading.list = false;
  }),
  immerOn(
    CargoesActions.load_Cargos_By_Location_Success,
    (state, { Cargos }) => {
      state.locationCargoes = Cargos;
      state.loading.list = false;
    }
  ),

  immerOn(
    CargoesActions.load_Cargoes_By_TRC_Success,
    (state, { Cargoes }) => {
      state.locationCargoes = Cargoes;
      state.loading.list = false;
    }
  ),

  immerOn(CargoesActions.load_Cargoes_By_TRC, (state) => { state.loading.list = true }),
  immerOn(CargoesActions.load_Cargoes_By_TRC_Failure, (state) => { state.loading.list = false }),

  immerOn(
    CargoesActions.add_One_Off_Cargo,
    (state, { oneOffCargo, weight }) => {
      state.locationCargoes.push(oneOffCargo);
      state.oneOffCargo = {
        cargoId: oneOffCargo.cargoId,
        ccuId: oneOffCargo.ccuId,
        description: oneOffCargo.ccuId,
        cargoExpectedDeliveryTime: oneOffCargo.cargoExpectedDeliveryTime.toISOString(),
        weight: weight,
        cargoFamilyName: oneOffCargo.cargoFamilyName || '',
        length: oneOffCargo.length || 0,
        width: oneOffCargo.width || 0,
      };
    }
  ),
  immerOn(
    CargoesActions.load_Cargos_Failure,
    CargoesActions.remove_Cargo_Success,
    CargoesActions.remove_Cargo_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(CargoesActions.add_Cargo, CargoesActions.edit_Cargo, (state) => {
    state.loading.createEdit = true;
  }),
  immerOn(
    CargoesActions.add_Cargo_Success,
    CargoesActions.add_Cargo_Failure,
    CargoesActions.edit_Cargo_Success,
    CargoesActions.edit_Cargo_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const cargoesFeature = createFeature({
  name: 'cargoes',
  reducer: cargoesReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});
