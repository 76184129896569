import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { initializeCargoDescriptionState } from '../states/cargo-description.state';
import { CargoDescriptionActions } from '../actions/cargo-description.action';

export const CargoDescriptionsReducer = createReducer(
  initializeCargoDescriptionState,
  immerOn(
    CargoDescriptionActions.load_Cargo_Descriptions,
    CargoDescriptionActions.remove_Cargo_Description,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(
    CargoDescriptionActions.load_Cargo_Descriptions_Success,
    (state, { cargoDescriptions }) => {
      state.cargoDescriptions = cargoDescriptions;
      state.loading.list = false;
    }
  ),
  immerOn(
    CargoDescriptionActions.load_Cargo_Descriptions_Failure,
    CargoDescriptionActions.remove_Cargo_Description_Success,
    CargoDescriptionActions.remove_Cargo_Description_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    CargoDescriptionActions.add_Cargo_Description,
    CargoDescriptionActions.edit_Cargo_Description,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    CargoDescriptionActions.add_Cargo_Description_Success,
    CargoDescriptionActions.add_Cargo_Description_Failure,
    CargoDescriptionActions.edit_Cargo_Description_Success,
    CargoDescriptionActions.edit_Cargo_Description_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  ),
  immerOn(
    CargoDescriptionActions.add_Cargo_Description_Success,
    CargoDescriptionActions.edit_Cargo_Description_Success,
    (state) => {
      state.isVisibleDialog = false;
      state.isEdit = false;
      state.cargoDescription = null;
    }
  ),

  immerOn(
    CargoDescriptionActions.is_Add_Edit_Dialog_Visible,
    (state, { isVisible, isEdit, cargoDescription }) => {
      state.isVisibleDialog = isVisible;
      state.isEdit = isEdit;
      state.cargoDescription = cargoDescription;
    }
  )
);

export const cargoDescriptionsFeature = createFeature({
  name: 'CargoDescriptions',
  reducer: CargoDescriptionsReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});
