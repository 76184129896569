import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map',
  standalone: true,
})
export class MapPipe implements PipeTransform {
  transform<T>(array: T[], mapper: (item: T) => any): any {
    return array.map(mapper);
  }
}