import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CargoService } from '../../cargo.service';
import { CargoesActions } from '../actions/cargoes.actions';
import { Cargo } from '../../interfaces/cargoes.interface';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { DialogOptions } from '../../../config/dialog-options';
import { CargoAddEditComponent } from 'apps/allocate/src/app/maintenance/shared/components/cargo-add-edit/cargo-add-edit.component';
import { CargoUnitType } from '../../interfaces/cargo-unit-type.interface';
import { VendorsActions } from '../actions/vendor.actions';
import { LocationActions } from '../actions/locations.actions';
import { OperatorActions } from '../actions/operators.actions';
import { CargoDescriptionActions } from '../actions/cargo-description.action';
import { TransportRequestCargoActions } from '../../../transport-requests/store/actions/transport-request-cargo.actions';

export const loadCargoesPage = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(CargoesActions.load_Cargo_Page),
      switchMap(() =>
        of(
          VendorsActions.load_Vendors(),
          CargoesActions.load_Cargo_Descriptions(),
          LocationActions.load_Locations(),
          OperatorActions.load_Operators(),
          CargoDescriptionActions.load_Cargo_Descriptions()
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadCargoes = createEffect(
  (actions = inject(Actions), cargoService = inject(CargoService)) => {
    return actions.pipe(
      ofType(
        CargoesActions.load_Cargos,
        CargoesActions.add_Cargo_Success,
        CargoesActions.edit_Cargo_Success,
        CargoesActions.remove_Cargo_Success,
        CargoesActions.approve_Cargo_Success
      ),
      mergeMap((action) =>
        cargoService
          .loadCargos(
            action.vendorId,
            action.status,
            action.from,
            action.to
          )
          .pipe(
            map((res: Cargo[]) =>
              CargoesActions.load_Cargos_Success({ Cargos: res })
            ),
            catchError((error: HttpErrorResponse) =>
              of(CargoesActions.load_Cargos_Failure({ error }))
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadCargoDesriptions = createEffect(
  (actions = inject(Actions), cargoService = inject(CargoService)) => {
    return actions.pipe(
      ofType(CargoesActions.load_Cargo_Descriptions),
      mergeMap(() =>
        cargoService.getCargoDescriptions().pipe(
          map((res: CargoUnitType[]) =>
            CargoesActions.load_Cargo_Descriptions_Success({
              cargoDescriptions: res,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoesActions.load_Cargo_Descriptions_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadCargoesByLocation = createEffect(
  (actions = inject(Actions), cargoService = inject(CargoService)) => {
    return actions.pipe(
      ofType(CargoesActions.load_Cargos_By_Location),
      switchMap(({ locationId }) =>
        cargoService.loadCargosListByLocation(locationId).pipe(
          map((res: Cargo[]) =>
            CargoesActions.load_Cargos_By_Location_Success({ Cargos: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoesActions.load_Cargos_By_Location_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadCargoesByLocationAndTRC = createEffect(
  (actions = inject(Actions), cargoService = inject(CargoService)) => {
    return actions.pipe(
      ofType(
        CargoesActions.load_Cargoes_By_TRC, 
        TransportRequestCargoActions.add_Transport_Requests_Cargo_Success,
        TransportRequestCargoActions.edit_Transport_Requests_Cargo_Success,
        TransportRequestCargoActions.bulk_update_Transport_Requests_Cargo_Success,
      ),
      switchMap(({ transportRequestId }) =>
        cargoService.loadCargoesByTRC(transportRequestId).pipe(
          map((res: Cargo[]) =>
            CargoesActions.load_Cargoes_By_TRC_Success({ Cargoes: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoesActions.load_Cargoes_By_TRC_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeCargo = createEffect(
  (actions = inject(Actions), cargoService = inject(CargoService)) => {
    return actions.pipe(
      ofType(CargoesActions.remove_Cargo),
      mergeMap((action) =>
        cargoService.removeCargo(action.id).pipe(
          map((res: Cargo) =>
            CargoesActions.remove_Cargo_Success({
              Cargo: res,
              successMessage: 'Cargo removed successfully!',
              vendorId: undefined,
              status: 'All',
              from: null,
              to: null,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoesActions.remove_Cargo_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addCargo = createEffect(
  (actions = inject(Actions), caragoService = inject(CargoService)) => {
    return actions.pipe(
      ofType(CargoesActions.add_Cargo),
      mergeMap((action) =>
        caragoService.addCargo(action.Cargo).pipe(
          map((res: Cargo) =>
            CargoesActions.add_Cargo_Success({
              Cargo: res,
              successMessage: 'Cargo added successfully!',
              vendorId: undefined,
              status: action.status,
              from: action.from,
              to: action.to,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoesActions.add_Cargo_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editCargo = createEffect(
  (actions = inject(Actions), cargoService = inject(CargoService)) => {
    return actions.pipe(
      ofType(CargoesActions.edit_Cargo),
      mergeMap((action) =>
        cargoService.editCargo(action.CargoId, action.Cargo).pipe(
          map((res: Cargo) =>
            CargoesActions.edit_Cargo_Success({
              Cargo: res,
              successMessage: 'Cargo edited successfully!',
              vendorId: undefined,
              status: action.status,
              from: action.from,
              to: action.to,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoesActions.edit_Cargo_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const approveCargo = createEffect(
  (actions = inject(Actions), cargoService = inject(CargoService)) => {
    return actions.pipe(
      ofType(CargoesActions.approve_Cargo),
      mergeMap((action) =>
        cargoService.approveCargo(action.CargoId).pipe(
          map(() =>
            CargoesActions.approve_Cargo_Success({
              successMessage: 'Cargo approved successfully!',
              vendorId: undefined,
              status: action.status,
              from: action.from,
              to: action.to,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CargoesActions.approve_Cargo_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const openCargoDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(CargoesActions.open_Cargo_Dialog),
      tap((action) => {
        dialog.open(CargoAddEditComponent, {
          ...dialogOptions,
          width: '640px',
          data: {
            cargo: action.Cargo,
            status: action.status,
            from: action.from,
            to: action.to,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);
