import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { Site } from '../../interfaces/site.interface';
import { errorProps } from '../../../../../../../auth/src/lib/store/auth.actions';
import { Location } from '../../interfaces/location.interface';
import { Constant } from 'libs/services/src/lib/services/shared/constant.interface';
import { Area } from '../../interfaces/area.interface';

export const SiteActions = createActionGroup({
  source: 'Site',
  events: {
    set_Site_Id: props<{ id: string }>(),
    load_Sites: emptyProps(),
    load_Sites_Success: props<{
      sites: Site[];
    }>(),
    load_Sites_Failure: errorProps(),
    remove_Site: props<{ id: string, locationId: string }>(),
    remove_Site_Success: props<{ site: Site; successMessage: string }>(),
    remove_Site_Failure: errorProps(),
    add_Site: props<{ site: Site }>(),
    add_Site_Success: props<{ site: Site; successMessage: string }>(),
    add_Site_Failure: errorProps(),
    edit_Site: props<{ id: string; site: Site }>(),
    edit_Site_Success: props<{ site: Site; successMessage: string }>(),
    edit_Site_Failure: errorProps(),
    load_Sites_By_Location_Id: props<{ id: string }>(),
    load_Sites_By_Location_Id_Success: props<{
      sites: Site[];
    }>(),
    load_Sites_By_Location_Id_Failure: errorProps(),
    load_Area_List: emptyProps(),
    load_Area_List_Success: props<{ areas: Constant[] }>(),
    load_Area_List_Failure: errorProps(),
    open_Area_Dialog: props<{ id: string; site: Site }>(),
    load_Areas: emptyProps(),
    load_Areas_Success: props<{
      areas: Area[];
    }>(),
    load_Areas_Failure: errorProps(),
  },
});
