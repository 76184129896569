import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, of, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { VendorWarehouseActions } from '../actions/vendor-warehouse.actions';
import { VendorWarehouseService } from '../../vendor-warehouse.service';

export const deleteVendorWarehouse = createEffect(
  (actions = inject(Actions), service = inject(VendorWarehouseService)) => {
    return actions.pipe(
      ofType(VendorWarehouseActions.remove_Vendor_Warehouse),
      switchMap(({ vendorWarehouseId }) =>
        service.deleteVendorWarehouse(vendorWarehouseId).pipe(
          switchMap(() => [
            VendorWarehouseActions.remove_Vendor_Warehouse_Success({
              successMessage: 'Vendor warehouse deleted Successfully',
            }),
          ]),
          catchError((error: HttpErrorResponse) =>
            of(VendorWarehouseActions.remove_Vendor_Warehouse_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);
