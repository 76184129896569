import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SearchQuery } from '../../../../../../../components/src/lib/interfaces/search-query.interface';
import { Location } from '../../interfaces/location.interface';
import { errorProps } from '../../../../../../../auth/src/lib/store/auth.actions';
import { District } from '../../interfaces/district.interfance';

export const DistrictActions = createActionGroup({
  source: 'District',
  events: {
    set_District_Id: props<{ id: string }>(),
    load_Districts: emptyProps(),
    load_Districts_Success: props<{
      districts: District[];
    }>(),
    load_Districts_Failure: errorProps(),
    remove_District: props<{ id: string, locationId: string }>(),
    remove_District_Success: props<{ district: District; successMessage: string }>(),
    remove_District_Failure: errorProps(),
    add_District: props<{ district: District }>(),
    add_District_Success: props<{ district: District; successMessage: string }>(),
    add_District_Failure: errorProps(),
    edit_District: props<{ id: string; district: District }>(),
    edit_District_Success: props<{ district: District; successMessage: string }>(),
    edit_District_Failure: errorProps(),
    Select_Items: props<{ selectedItems: District[] }>(),
    load_Districts_By_Location_Id: props<{ id: string }>(),
    load_Districts_By_Location_Id_Success: props<{
      districts: District[];
    }>(),
    load_Districts_By_Location_Id_Failure: errorProps(),
  },
});
