import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { currentUserFeature } from 'libs/auth/src/lib/store/current-user/current-user.features';

@Injectable({ providedIn: 'root' })
export class TimezoneService {
  store = inject(Store);
  currentUser = this.store.selectSignal(currentUserFeature.selectUser);

  toUTC(localDate: Date): Date {
    const user = this.currentUser();
    if (user && user.locationTimeZoneInfoId) {
      const localDateTimeString = DateTime.fromJSDate(localDate).toFormat(
        "yyyy-MM-dd'T'HH:mm:ss"
      );

      const userTimeZoneDateTime = DateTime.fromISO(localDateTimeString, {
        zone: user.locationTimeZoneInfoId,
      });
      return userTimeZoneDateTime.toUTC().toJSDate();
    }

    return localDate;
  }

  fromUTC(utcDate: Date): string {
    const user = this.currentUser();
    if (user && user.locationTimeZoneInfoId) {
      return DateTime.fromJSDate(utcDate, { zone: 'utc' })
        .setZone(user.locationTimeZoneInfoId)
        .toFormat('dd-MM-yyyy HH:mm');
    }
    return DateTime.fromJSDate(utcDate).toString();
  }
}
