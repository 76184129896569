import { NgIf } from '@angular/common';
import { Component, inject, Input, computed } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { PortalModule } from '@angular/cdk/portal';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { MenuItem, SharedModule } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';

import { PortalService } from '../../services/portal.service';
import { UserActions } from 'libs/services/src/lib/services/maintenance/store/actions/users.actions';
import { User } from 'libs/auth/src/lib/interfaces/user.interface';
import { currentUserFeature } from 'libs/auth/src/lib/store/current-user/current-user.features';
import { clientLocationsFeature } from 'libs/services/src/lib/services/client-locations/store/features/client-locations.features';
import { ClientLocation } from 'libs/services/src/lib/services/client-locations/interfaces/client-locations.interface';
import { FilterByPipe } from '../../pipes';
import { AuthActions } from 'libs/auth/src/lib/store/auth.actions';

@Component({
  standalone: true,
  selector: 'lha-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    PortalModule,
    NgIf,
    MenuModule,
    TooltipModule,
    FilterByPipe,
    SharedModule,
    RouterLink,
  ],
})
export class HeaderComponent {
  private readonly router = inject(Router);
  private readonly portalService = inject(PortalService);
  @Input() title: string = '';
  store = inject(Store);
  actions = inject(Actions);

  clientLocations = this.store.selectSignal(
    clientLocationsFeature.selectClientLocations
  );
  portal = toSignal(this.portalService.getPortal('header'));
  url = window.location.origin;
  parts = this.url.split('//')[1].split('.');
  tenantName = this.parts[0];
  currentUserState = this.store.selectSignal(
    currentUserFeature.selectCurrentUserState
  );

  locationsItems = computed<MenuItem[]>(() => {
    return this.clientLocations().map((item) => {
      return {
        label: item.locationName,
        command: () => {
          this.updateUserActiveLocation(item.locationId);
        },
      };
    });
  });

  menuProfile: MenuItem[] = [
    {
      label: 'Profile',
      command: () => {
        this.showProfile();
      },
    },
    {
      label: 'Logout',
      command: () => {
        this.logout();
      },
    },
  ];

  menuApp = computed<MenuItem[]>(() => {
    return [
      {
        label: 'Allocate',
        title: 'Allocate',
        visible: this.currentUserState().hasAllocatePermissions,
        url: this.url + '/allocate/',
        icon: 'assets/allocate.svg',
      },
      {
        label: 'Plan',
        title: 'Port Activity Planning',
        visible: this.currentUserState().hasPlanPermissions,
        url: this.url + '/plan/',
        icon: 'assets/plan.svg',
      },
      {
        label: 'Flow',
        title: 'Quayside Management',
        visible: this.currentUserState().hasFlowPermissions,
        url: this.url + '/flow/',
        icon: 'assets/flow.svg',
      },
      {
        label: 'Contain',
        title: 'CCU Fleet Management',
        visible: this.currentUserState().hasContainPermissions,
        url: this.url + '/contain/',
        icon: 'assets/contain.svg',
      },
      {
        label: 'Request',
        title: 'Offshore Transport Request',
        visible: this.currentUserState().hasRequestPermissions,
        url: this.url + '/request/',
        icon: 'assets/request.svg',
      },
    ];
  });

  byLocationId = (locationId: string | null) => (item: ClientLocation) =>
    item?.locationId === locationId;

  showProfile() {
    this.router.navigateByUrl('profile');
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  updateUserActiveLocation(locationId: string) {
    if (
      this.currentUserState().user &&
      this.currentUserState().user.locationId !== locationId
    ) {
      const updatedUser = {
        ...this.currentUserState().user,
        locationId: locationId,
      } as User;
      this.store.dispatch(
        UserActions.edit_User_Location({
          user: updatedUser,
          userId: this.currentUserState().user.userId,
        })
      );
    }
  }
}
