import { CargoDescription } from '../../interfaces/cargo-description.interface';
import { MaintenanceState } from '../../interfaces/maintenance-state.interface';
import { maintenanceInitialState } from './maintenance.state';

export interface CargoDescriptionState extends MaintenanceState {
  cargoDescriptions: CargoDescription[];
  cargoDescription: CargoDescription | null;
  isEdit: boolean;
  isVisibleDialog: boolean;
}

export const initializeCargoDescriptionState: CargoDescriptionState = {
  cargoDescriptions: [],
  cargoDescription: null,
  isEdit: false,
  isVisibleDialog: false,
  ...maintenanceInitialState,
};
