import { createReducer, createFeature, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { PoolsActions } from '../actions/pool.actions';
import { initializePoolsState } from '../states/pool.state';

export const poolsReducer = createReducer(
  initializePoolsState,

  immerOn(PoolsActions.load_Pools, (state) => {
    state.loading.list = true;
  }),
  immerOn(PoolsActions.load_Pools_Success, (state, payload) => {
    state.poolsList = payload.poolList;
    state.loading.list = false;
  }),
  immerOn(PoolsActions.update_Pools_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),
  immerOn(PoolsActions.load_Pools_Failure, (state) => {
    state.loading.list = false;
  }),
  immerOn(PoolsActions.load_Pools_Failure, (state) => {
    state.loading.list = false;
  }),
  immerOn(PoolsActions.add_Pool, PoolsActions.edit_Pool, (state) => {
    state.loading.createEdit = true;
  }),
  immerOn(
    PoolsActions.add_Pool_Success,
    PoolsActions.add_Pool_Failure,
    PoolsActions.edit_Pool_Success,
    PoolsActions.edit_Pool_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  ),
  immerOn(PoolsActions.revert_Pool_State, (state, { poolId, pool }) => {
    return {
      ...state,
      poolsList: state.poolsList.map((p) => (p.poolId === poolId ? pool : p)),
    };
  })
);

export const poolsFeature = createFeature({
  name: 'pools',
  reducer: poolsReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});
