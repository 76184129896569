import {
  ChangeDetectionStrategy,
  Component, computed,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  VendorsActions
} from '../../../../../services/src/lib/services/maintenance/store/actions/vendor.actions';
import { NgForOf, NgIf } from '@angular/common';
import { locationsFeature } from '../../../../../services/src/lib/services/maintenance/store/features';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Vendor } from '../../../../../services/src/lib/services/maintenance/interfaces/vendor.interface';
import { vendorsFeature } from '../../../../../services/src/lib/services/maintenance/store/features/vendors.feature';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CountryService } from '../../../../../services/src/lib/services/country.service';
import {
  VendorWarehouseActions
} from '../../../../../services/src/lib/services/maintenance/store/actions/vendor-warehouse.actions';
import { districtFeature } from '../../../../../services/src/lib/services/maintenance/store/features/district.feature';
import { DistrictActions } from '../../../../../services/src/lib/services/maintenance/store/actions/district.actions';

@Component({
  selector: 'lha-vendors-add-edit',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    DialogModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    NgForOf
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './vendors-add-edit.component.html',
  styleUrls: ['./vendors-add-edit.component.scss'],
})
export class VendorsAddEditComponent implements OnInit, OnChanges {
  private readonly destroyRef = inject(DestroyRef);
  @Input() data!: Vendor | null;
  @Input() voyageId: string | null = null;
  @Input() dialogVisible: boolean = false;
  @Output() dialogToggle = new EventEmitter<void>();
  store = inject(Store);
  action = inject(Actions);
  vendorsState = this.store.selectSignal(vendorsFeature.selectVendorsState);
  private indexToRemove: number | null = null;
  locations = this.store.selectSignal(locationsFeature.selectLocations);
  districts = this.store.selectSignal(districtFeature.selectDistricts);
  districtList = computed(() => {
    return [...this.districts(),];
  });
  isAdd = true;
  vendor!: Vendor | null;
  countryService = inject(CountryService);
  form = new FormGroup({
    vendorName: new FormControl<string>('', [Validators.required]),
    locationId: new FormControl<string>('', [Validators.required]),
    country: new FormControl<string>(''),
    vendorWarehouses: new FormArray([])
  });

  countries = this.countryService.getCountryList();

  ngOnInit(): void {
    this.subCloseDialog();

    this.action.pipe(
      ofType(VendorWarehouseActions.remove_Vendor_Warehouse_Success),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      if (this.indexToRemove !== null) {
        this.warehousesCtrl.removeAt(this.indexToRemove);
        this.indexToRemove = null;
      }
    });
  }

  onLocationChange(locationId: string) {
    const warehouses = this.form.get('vendorWarehouses') as FormArray;
    warehouses.controls.forEach(warehouse => {
      warehouse.get('districtId')?.setValue('');
    });
    this.store.dispatch(DistrictActions.load_Districts_By_Location_Id({ id: locationId }));
  }


  get isAddWarehouseDisabled(): boolean {
    return this.warehousesCtrl.controls.some(control => control.invalid);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.initAddEdit();
    } else {
      this.resetForm();
    }

  }

  hideDialog() {
    this.dialogVisible = false;
    this.dialogToggle.emit();
    this.resetForm();
  }

  resetForm() {
    this.form.reset();
    this.warehousesCtrl.clear();
    this.addWarehouse();
  }

  get warehousesCtrl(): FormArray {
    return this.form.get('vendorWarehouses') as FormArray;
  }

  addWarehouse() {
    this.warehousesCtrl.push(new FormGroup({
      name: new FormControl<string>('', [Validators.required, Validators.maxLength(100)]),
      address: new FormControl<string>('', [Validators.required, Validators.maxLength(100)]),
      postCode: new FormControl<string>('', [Validators.required, Validators.maxLength(100)]),
      city: new FormControl<string>('', [Validators.required, Validators.maxLength(100)]),
      districtId: new FormControl<string>('', [Validators.required])
    }));
  }

  private ensureAtLeastOneWarehouse(): void {
    if (this.warehousesCtrl.length === 0) {
      this.addWarehouse();
    }
  }

  removeWarehouse(index: number) {
    const vendorWarehouse = this.warehousesCtrl.controls[index].value


    if (vendorWarehouse.vendorWarehouseId) {
      this.indexToRemove = index;
      this.store.dispatch(
        VendorWarehouseActions.remove_Vendor_Warehouse({
          vendorWarehouseId: vendorWarehouse.vendorWarehouseId
        })
      );
    } else {
      this.warehousesCtrl.removeAt(index);
    }
  }

  private initAddEdit(): void {
    this.isAdd = !this.data;
    if (!this.isAdd) {
      this.vendor = this.data;
      this.pathForm(this.vendor!);
    } else {
      this.resetForm();
    }
  }

  handleSave() {
    this.dialogVisible = !this.dialogVisible;
  }

  private subCloseDialog(): void {
    this.action
      .pipe(
        ofType(
          VendorsActions.add_Vendor_Success,
          VendorsActions.edit_Vendor_Success
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogVisible = false
        this.dialogToggle.emit();
      });
  }

  private pathForm(vendor: Vendor): void {
    this.form.patchValue({
      vendorName: vendor.vendorName,
      locationId: vendor.locationId,
      country: vendor.country,
    });

    this.store.dispatch(DistrictActions.load_Districts_By_Location_Id({ id: vendor.locationId }));


    this.warehousesCtrl.clear();

    vendor.vendorWarehouses.forEach((warehouse, index) => {
      this.warehousesCtrl.push(new FormGroup({
        vendorId: new FormControl<string>(vendor.vendorId),
        vendorWarehouseId: new FormControl<string>(warehouse.vendorWarehouseId),
        districtId: new FormControl<string>(warehouse.districtId),
        name: new FormControl<string>(warehouse.name, [Validators.required]),
        address: new FormControl<string>(warehouse.address, [Validators.required]),
        city: new FormControl<string>(warehouse.city, [Validators.required]),
        postCode: new FormControl<string>(warehouse.postCode, [Validators.required])
      }));
    });

  }

  saveVendor(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const model = {
      voyageId: this.voyageId ? this.voyageId : null,
      ...this.form.value,
    } as unknown as Vendor;

    if (this.isAdd) {
      this.store.dispatch(VendorsActions.add_Vendor({ vendor: model }));
      this.dialogToggle.emit();
      this.resetForm();
    } else {
      this.store.dispatch(
        VendorsActions.edit_Vendor({
          vendor: model,
          vendorId: this.vendor!.vendorId,
        })
      );
      this.dialogToggle.emit();
      this.resetForm();
    }
  }
}
