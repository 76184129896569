import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CargoDescription } from '../../interfaces/cargo-description.interface';
import { errorProps } from '../../../../../../../components/src/lib/functions/utility.functions';

export const CargoDescriptionActions = createActionGroup({
  source: 'Tank Type',
  events: {
    load_Cargo_Description_Page: emptyProps(),
    load_Cargo_Descriptions: emptyProps(),
    load_Cargo_Descriptions_Success: props<{
      cargoDescriptions: CargoDescription[];
    }>(),
    load_Cargo_Descriptions_Failure: errorProps(),
    remove_Cargo_Description: props<{ id: string }>(),
    remove_Cargo_Description_Success: props<{
      cargoDescription: CargoDescription;
      successMessage: string;
    }>(),
    remove_Cargo_Description_Failure: errorProps(),
    open_Cargo_Description_Dialog: props<{
      cargoDescription: CargoDescription | null;
    }>(),
    add_Cargo_Description: props<{ cargoDescription: CargoDescription }>(),
    add_Cargo_Description_Success: props<{
      cargoDescription: CargoDescription;
      successMessage: string;
    }>(),
    add_Cargo_Description_Failure: errorProps(),
    edit_Cargo_Description: props<{
      cargoDescriptionId: string;
      cargoDescription: CargoDescription;
    }>(),
    edit_Cargo_Description_Success: props<{
      cargoDescription: CargoDescription;
      successMessage: string;
    }>(),
    edit_Cargo_Description_Failure: errorProps(),
    is_Add_Edit_Dialog_Visible: props<{
      isVisible: boolean;
      isEdit: boolean;
      cargoDescription: CargoDescription | null;
    }>(),
  },
});
