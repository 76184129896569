import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { AssetService } from '../../asset.service';
import { ConstantService } from '../../../constant.service';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AssetActions } from '../actions/assets.actions';
import { Asset } from '../../interfaces/asset.interface';
import { Store } from '@ngrx/store';
import { assetsFeature } from '../features';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { FileService } from '../../../file.service';
import { AssetAddEditComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/asset-add-edit/asset-add-edit.component';
import { Constant } from 'libs/services/src/lib/services/shared/constant.interface';
import { DialogOptions } from '../../../config/dialog-options';
import { AssetHistoryComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/asset-history/asset-history.component';
import { OperatorActions } from '../actions/operators.actions';
import { LocationActions } from '../actions/locations.actions';

export const loadAssetPage = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(AssetActions.load_Asset_Page),
      switchMap(() =>
        of(
          AssetActions.load_Assets(),
          AssetActions.load_Asset_Types(),
          OperatorActions.load_Operators(),
          LocationActions.load_Locations()
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const load_offshore_locations = createEffect(
  (
    actions = inject(Actions),
    assetService = inject(AssetService)
  ) => {
    return actions.pipe(
      ofType(
        AssetActions.load_Assets_By_Location,
      ),
      switchMap(() =>
        assetService.loadAssetsByLocation().pipe(
          map((result: Asset[]) => AssetActions.load_Assets_By_Location_Success({ assets: result })),
          catchError((error: HttpErrorResponse) =>
            of(AssetActions.load_Assets_By_Location_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadAssets = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    assetService = inject(AssetService)
  ) => {
    return actions.pipe(
      ofType(
        AssetActions.load_Assets,
        AssetActions.update_Asset_Queries,
        AssetActions.remove_Asset_Success,
        AssetActions.add_Asset_Success,
        AssetActions.edit_Asset_Success
      ),
      concatLatestFrom(() => store.select(assetsFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap((action) =>
        assetService.loadAssets(action).pipe(
          map((res: Asset[]) =>
            AssetActions.load_Assets_Success({ assets: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(AssetActions.load_Assets_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeAsset = createEffect(
  (actions = inject(Actions), assetService = inject(AssetService)) => {
    return actions.pipe(
      ofType(AssetActions.remove_Asset),
      mergeMap((action) =>
        assetService.removeAsset(action.id).pipe(
          map((res: Asset) =>
            AssetActions.remove_Asset_Success({
              asset: res,
              successMessage: 'Asset removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(AssetActions.remove_Asset_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addAsset = createEffect(
  (actions = inject(Actions), assetService = inject(AssetService)) => {
    return actions.pipe(
      ofType(AssetActions.add_Asset),
      mergeMap((action) =>
        assetService.addAsset(action.asset).pipe(
          map((res: Asset) =>
            AssetActions.add_Asset_Success({
              asset: res,
              successMessage: 'Asset added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(AssetActions.add_Asset_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editAsset = createEffect(
  (actions = inject(Actions), assetService = inject(AssetService)) => {
    return actions.pipe(
      ofType(AssetActions.edit_Asset),
      mergeMap((action) =>
        assetService
          .editAsset(action.assetId, action.asset, action.addNewHistory)
          .pipe(
            map((res: Asset) =>
              AssetActions.edit_Asset_Success({
                asset: res,
                successMessage: 'Asset edited successfully!',
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(AssetActions.edit_Asset_Failure({ error: error }))
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadAssetsAssetTypes = createEffect(
  (actions = inject(Actions), constantService = inject(ConstantService)) => {
    return actions.pipe(
      ofType(AssetActions.load_Asset_Types),
      mergeMap(() =>
        constantService.loadAssetTypes().pipe(
          map((res: Constant[]) =>
            AssetActions.load_Asset_Types_Success({ assetTypes: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(AssetActions.load_Asset_Types_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadClusterHeadAssets = createEffect(
  (actions = inject(Actions), assetService = inject(AssetService)) => {
    return actions.pipe(
      ofType(AssetActions.load_Cluster_Heads),
      mergeMap((action) =>
        assetService.clusterHeadAsset(action.id).pipe(
          map((res: Asset[]) =>
            AssetActions.load_Cluster_Heads_Success({ assetsHead: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(AssetActions.load_Cluster_Heads_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const exportAssets = createEffect(
  (
    actions = inject(Actions),
    assetService = inject(AssetService),
    fileService = inject(FileService)
  ) => {
    return actions.pipe(
      ofType(AssetActions.export_Assets),
      mergeMap(() =>
        assetService.exportAssets().pipe(
          map((res: ArrayBuffer) => {
            fileService.downloadFile(res, 'Assets');
            return AssetActions.export_Assets_Success();
          }),
          catchError((error: HttpErrorResponse) =>
            of(AssetActions.export_Assets_Failure({ error }))
          )
        )
      )
    );
  },
  {
    // You can omit this part if not needed
    // This indicates that the effect is a pure function and can be tree-shaken during optimization
    functional: true,
  }
);

export const openAssetDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    dialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(AssetActions.open_Asset_Dialog),
      tap((action) => {
        dialog.open(AssetAddEditComponent, {
          ...dialogOptions,
          data: {
            asset: action.asset,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

export const openAssetHistoryDialog = createEffect(
  (actions = inject(Actions), dialog = inject(MatDialog)) => {
    return actions.pipe(
      ofType(AssetActions.open_Asset_History_Dialog),
      tap((action) => {
        dialog.open(AssetHistoryComponent, {
          width: '80vw',
          panelClass: 'asset_dialog',
          autoFocus: false,
          disableClose: true,
          height: '80vh',
          data: {
            assetHistory: action.assetHistory,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);
