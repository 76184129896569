import { MaintenanceState } from "../../interfaces/maintenance-state.interface";
import { Pool } from "../../interfaces/pool.interface";
import { maintenanceInitialState } from "./maintenance.state";

export interface PoolsState extends MaintenanceState {
    poolsList: Pool[];  
}

export const initializePoolsState: PoolsState = {
    poolsList: [],
    ...maintenanceInitialState,
};