import { createActionGroup, props } from '@ngrx/store';
import { errorProps } from 'libs/components/src/lib/functions/utility.functions';

export const VendorWarehouseActions = createActionGroup({
  source: '[VendorWarehouse]',
  events: {
    remove_Vendor_Warehouse: props<{vendorWarehouseId: string}>(),
    remove_Vendor_Warehouse_Success: props<{successMessage: string}>(),
    remove_Vendor_Warehouse_Failure: errorProps(),

  }
})
