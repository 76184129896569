import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AssetMobileWell } from './interfaces/asset-mobile-well.interface';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AssetMobileWellService {
  private readonly http = inject(HttpClient);

  loadAssetMobileWells(id: string): Observable<AssetMobileWell[]> {
    return this.http.get<AssetMobileWell[]>(`/api/mobilewell/mobile/${id}`);
  }

  removeAssetMobileWell(id: string): Observable<AssetMobileWell> {
    return this.http.delete<AssetMobileWell>(`/api/mobilewell/${id}`);
  }

  addAssetMobileWell(
    assetMobileWell: AssetMobileWell
  ): Observable<AssetMobileWell> {
    return this.http.post<AssetMobileWell>('/api/mobilewell', assetMobileWell);
  }

  editAssetMobileWell(
    id: string,
    assetMobileWell: AssetMobileWell
  ): Observable<AssetMobileWell> {
    return this.http.put<AssetMobileWell>(
      `/api/mobilewell/${id}`,
      assetMobileWell
    );
  }
}
