import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, takeUntil } from 'rxjs';
import { CellTemplateDirective } from '../../../../../../../../libs/components/src/lib/components/table/cell-template.directive';
import { CustomChipComponent } from '../../../../../../../../libs/components/src/lib/components/custom-chip/custom-chip.component';
import { AsyncPipe, DatePipe, NgIf, CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TableComponent } from '../../../../../../../../libs/components/src/lib/components/table/table.component';
import { Column } from '../../../../../../../../libs/components/src/lib/components/table/column.model';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDatetimePickerModule,
} from '@angular-material-components/datetime-picker';
import { SingleSelectComponent } from '../../../../../../../../libs/components/src/lib/components/single-select/single-select.component';
import { confirmActions } from 'libs/components/src/lib/store/confirm.actions';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { ActivityCategoryType } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/activity-category-type.interface';
import { activityCategoryTypeFeature } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/features/activity-category-type.feature';
import { ActivityCategory } from '../../../../../../../../libs/services/src/lib/services/maintenance/interfaces/activity-category.interface';
import { ActivityCategoryTypeActions } from '../../../../../../../../libs/services/src/lib/services/maintenance/store/actions/activity-category-type.actions';
import { SearchQuery } from '../../../../../../../../libs/components/src/lib/interfaces/search-query.interface';
import { maintenanceInitialState } from 'libs/services/src/lib/services/maintenance/store/states/maintenance.state';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const TIME_FORMAT = {
  parse: {
    dateInput: 'DD-MM-YYYY, HH:mm',
  },
  display: {
    dateInput: 'DD-MM-YYYY, HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MMMM',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'lha-activity-category-type-add-edit',
  standalone: true,
  imports: [
    CellTemplateDirective,
    CustomChipComponent,
    DatePipe,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    NgIf,
    TableComponent,
    AsyncPipe,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatDatetimePickerModule,
    ReactiveFormsModule,
    DatePipe,
    CdkDrag,
    CdkDragHandle,
    MatSlideToggleModule,
    CommonModule,
  ],
  providers: [
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: TIME_FORMAT,
    },
    DatePipe,
  ],
  templateUrl: './activity-category-type-add-edit.component.html',
  styleUrls: ['./activity-category-type-add-edit.component.scss'],
})
export class ActivityCategoryTypeAddEditComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  @Output() save = new EventEmitter<boolean>();
  dialogRef = inject(MatDialogRef<ActivityCategoryTypeAddEditComponent>);
  data: { id: string; activityCategory: ActivityCategory } =
    inject(MAT_DIALOG_DATA);
  store = inject(Store);
  action = inject(Actions);
  query: SearchQuery = maintenanceInitialState.query;
  activityCategoryTypeState = this.store.selectSignal(
    activityCategoryTypeFeature.selectActivityCategoryTypeState
  );
  activityCategoryTypes = this.store.selectSignal(
    activityCategoryTypeFeature.selectActivityCategoryTypes
  );
  datePipe = inject(DatePipe);
  columns: Column<ActivityCategoryType>[] = [];
  editRows: Map<string, FormGroup> = new Map();
  formGroup = new FormControl();

  ngOnInit(): void {
    this.getColumns(this.data.activityCategory.activityType);
    this.store.dispatch(
      ActivityCategoryTypeActions.set_Activity_Category_Type_Id({
        id: this.data.id,
      })
    );
    this.subToActions();
  }

  getColumns(activityType: number) {
    if (activityType === 3) {
      this.columns = [
        new Column('name', 'Activity Category Type', { sortHeader: false }),
        new Column('isInboundLifting', 'Inbound Lifting', {
          sortHeader: false,
        }),
        new Column('createOutOfPortActivity', 'Out of Port Activity', {
          sortHeader: false,
        }),
        new Column('createdDate', 'Created Date', { sortHeader: false }),
        new Column('activityCategoryTypeId', 'Actions', { sortHeader: false }),
      ];
    } else {
      this.columns = [
        new Column('name', 'Activity Category Type', { sortHeader: false }),
        new Column('createOutOfPortActivity', 'Out of Port Activity', {
          sortHeader: false,
        }),
        new Column('createdDate', 'Created Date', { sortHeader: false }),
        new Column('activityCategoryTypeId', 'Actions', { sortHeader: false }),
      ];
    }
  }

  private subToActions(): void {
    this.action
      .pipe(
        ofType(ActivityCategoryTypeActions.add_Activity_Category_Type_Success),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.editRows.delete('add');
      });

    this.action
      .pipe(
        ofType(ActivityCategoryTypeActions.edit_Activity_Category_Type_Success),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((res) => {
        this.editRows.delete(res.activityCategoryType.activityCategoryTypeId);
      });
  }

  addActivityCategoryType(): void {
    if (this.editRows.get('add')) {
      return;
    }

    if (
      this.data.activityCategory.activityType === 3 &&
      this.activityCategoryTypes().length >= 2
    ) {
      return;
    }

    this.store.dispatch(
      ActivityCategoryTypeActions.add_Activity_Category_Type_Row()
    );
    this.editRows.set('add', this.createActivityCategoryTypeFormGroup());
  }

  saveActivityCategoryType(activityCategoryType: ActivityCategoryType): void {
    const model = {
      ...this.editRows.get(activityCategoryType.activityCategoryTypeId)?.value,
      activityCategoryId: this.data.id,
    };

    if (activityCategoryType.activityCategoryTypeId === 'add') {
      this.store.dispatch(
        ActivityCategoryTypeActions.add_Activity_Category_Type({
          activityCategoryType: model,
        })
      );
    } else {
      this.store.dispatch(
        ActivityCategoryTypeActions.edit_Activity_Category_Type({
          id: activityCategoryType.activityCategoryTypeId,
          activityCategoryType: model,
        })
      );
    }
    this.save.emit(true);
  }

  cancelActivityCategoryType(activityCategoryType: ActivityCategoryType): void {
    this.editRows.delete(activityCategoryType.activityCategoryTypeId);
    if (activityCategoryType.activityCategoryTypeId === 'add') {
      this.store.dispatch(
        ActivityCategoryTypeActions.remove_Activity_Category_Type_Row()
      );
    }
  }

  editActivityCategoryType(activityCategoryType: ActivityCategoryType): void {
    this.editRows.set(
      activityCategoryType.activityCategoryTypeId,
      this.createActivityCategoryTypeFormGroup(activityCategoryType)
    );
  }

  removeActivityCategoryType(activityCategoryType: ActivityCategoryType): void {
    this.store.dispatch(
      confirmActions.request_Confirmation({
        titles: {
          title: 'Do you want to remove this Activity Category Type?',
          btnConfirm: 'Yes Delete',
        },
        confirm: ActivityCategoryTypeActions.remove_Activity_Category_Type({
          id: activityCategoryType.activityCategoryTypeId,
        }),
      })
    );
  }

  createActivityCategoryTypeFormGroup(
    activityCategoryType?: ActivityCategoryType
  ): FormGroup {
    const formGroup = new FormGroup({
      name: new FormControl(activityCategoryType?.name, [Validators.required]),
      createOutOfPortActivity: new FormControl(
        activityCategoryType?.createOutOfPortActivity ?? false
      ),
      isInboundLifting: new FormControl(
        activityCategoryType?.isInboundLifting ?? false
      ),
    });

    const anyInboundLifting = this.activityCategoryTypes().some(
      (type) => type.isInboundLifting
    );
    if (anyInboundLifting && !activityCategoryType?.isInboundLifting) {
      formGroup.get('isInboundLifting')?.disable();
    }

    formGroup.get('isInboundLifting')?.valueChanges.subscribe((value) => {
      this.activityCategoryTypes().forEach((type) => {
        if (type.isInboundLifting && type !== activityCategoryType) {
          this.editRows
            .get(type.activityCategoryTypeId)
            ?.get('isInboundLifting')
            ?.setValue(false);
        }
      });
      this.setOutOfPortActivity(formGroup, !value);
    });

    return formGroup;
  }

  setOutOfPortActivity(formGroup: FormGroup, value: boolean): void {
    if (value) {
      formGroup.get('createOutOfPortActivity')?.enable({ emitEvent: false });
    } else {
      formGroup.get('createOutOfPortActivity')?.disable({ emitEvent: false });
      formGroup
        .get('createOutOfPortActivity')
        ?.setValue(false, { emitEvent: false });
    }
  }

  getFg(id: string): FormGroup {
    return this.editRows.get(id) ?? this.createActivityCategoryTypeFormGroup();
  }

  private formatDate(date: string | Date, skipTime = false): string {
    const dateFormat = skipTime ? 'dd/MM/yy' : 'dd/MM/yy HH:mm';
    return this.datePipe.transform(date, dateFormat) || '';
  }
}
