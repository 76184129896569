import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class VendorWarehouseService {
  private readonly http = inject(HttpClient);

  deleteVendorWarehouse(vendorWarehouseId: string) {
    return this.http.delete(`/api/vendorWarehouse/${vendorWarehouseId}`);
  }
}
