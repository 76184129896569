import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { DistrictActions } from '../actions/district.actions';
import { initializeDistrictState } from '../states/district.state';
import { locationsFeature } from './locations.features';

export const districtReducer = createReducer(
  initializeDistrictState,
  immerOn(
    DistrictActions.remove_District,
    DistrictActions.load_Districts,
    DistrictActions.load_Districts_By_Location_Id,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(
    DistrictActions.load_Districts_Success,
    (state, { districts }) => {
      state.districts = districts;
      state.loading.list = false;
    }
  ),
  immerOn(
    DistrictActions.set_District_Id,
    (state, { id }) => {
      state.districtId = id;
    }
  ),
  immerOn(
    DistrictActions.load_Districts_Failure,
    DistrictActions.remove_District_Success,
    DistrictActions.remove_District_Failure,
    DistrictActions.load_Districts_By_Location_Id_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    DistrictActions.add_District,
    DistrictActions.edit_District,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    DistrictActions.add_District_Success,
    DistrictActions.add_District_Failure,
    DistrictActions.edit_District_Success,
    DistrictActions.edit_District_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  ),
  immerOn(
    DistrictActions.load_Districts_By_Location_Id_Success,
    (state, { districts }) => {
      state.districts = districts;
      state.loading.list = false;
    }
  ),
);

export const districtFeature = createFeature({
  name: 'district',
  reducer: districtReducer,
  extraSelectors: ({ selectLoading, selectDistricts }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
    selectDistricts: createSelector(
      selectDistricts,
      (districts) => districts
    ),
    selectLocation: (locations: string[]) =>
      createSelector(locationsFeature.selectLocations, (location) =>
        location.filter((item) => locations.includes(item.name))),
  }),
});
