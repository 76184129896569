import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'joinBy'
})
export class JoinByPipe implements PipeTransform {

  transform<T>(value: T[], separator: string = ', '): string {
    if (!Array.isArray(value)) {
      return '';
    }
    return value.join(separator);
  }

}
