import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { initializeVendorsState } from '../states/vendors.state';
import { VendorsActions } from '../actions/vendor.actions';

export const vendorsReducer = createReducer(
  initializeVendorsState,
  immerOn(
    VendorsActions.load_Vendors,
    VendorsActions.load_Vendors_By_Location_Id,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(VendorsActions.load_Vendors_Success, (state, payload) => {
    state.vendorsList = payload.vendorList;
    state.loading.list = false;
  }),
  immerOn(
    VendorsActions.load_Vendors_By_Location_Id_Success,
    (state, payload) => {
      state.locationVendorsList = payload.vendors;
      state.loading.list = false;
    }
  ),
  immerOn(VendorsActions.update_Vendor_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),
  immerOn(VendorsActions.load_Vendors_Failure, (state) => {
    state.loading.list = false;
  }),
  immerOn(
    VendorsActions.load_Vendors_By_Voyage_Id_Success,
    (state, payload) => {
      state.voyageVendorsList = payload.vendorList;
    }
  ),
  immerOn(
    VendorsActions.load_Vendor_Addresses_By_Voyage_Id_Success,
    (state, payload) => {
      state.addressesList = payload.addressesList;
    }
  ),
  immerOn(
    VendorsActions.load_Vendors_Failure,
    VendorsActions.remove_Vendor_Success,
    VendorsActions.remove_Vendor_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(VendorsActions.add_Vendor, VendorsActions.edit_Vendor, (state) => {
    state.loading.createEdit = true;
  }),
  immerOn(
    VendorsActions.add_Vendor_Success,
    VendorsActions.add_Vendor_Failure,
    VendorsActions.edit_Vendor_Success,
    VendorsActions.edit_Vendor_Failure,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const vendorsFeature = createFeature({
  name: 'vendors',
  reducer: vendorsReducer,
  extraSelectors: ({ selectLoading }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
  }),
});
