<div *ngIf="currentUser$">
  <lha-header></lha-header>

  <div class="main-content">
    <div class="welcome-section">
      <h2 class="welcome-text">
        Welcome
        <span *ngIf="(currentUser$ | async)?.firstname; else loading">
          {{ (currentUser$ | async)?.firstname }}!
        </span>
        <ng-template #loading>
          <span id="username"></span>
          <span class="ellipsis-animation"></span>
        </ng-template>
      </h2>
    </div>
    <div class="app-section">
      <h2 class="all-apps-text">All apps</h2>
      <div class="content-area app-content"></div>
    </div>

    <div class="activities-notifications">
      <div class="activities-section">
        <div class="recent-activities-container">
          <h2 class="header">Recent Activities</h2>
          <div class="content-area recent-activities-content"></div>
        </div>
      </div>

      <div class="notifications-section">
        <div class="notifications-container">
          <h2 class="header">Notifications (ie. HSEQs)</h2>
          <div class="content-area notifications-content"></div>
        </div>
      </div>
    </div>
  </div>
</div>
