import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { locationsInitialState } from '../states/locations.state';
import { LocationActions } from '../actions/locations.actions';

export const locationsReducer = createReducer(
  locationsInitialState,
  immerOn(
    LocationActions.remove_Location,
    LocationActions.load_Locations,
    LocationActions.load_timezones,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(LocationActions.update_Location_Queries, (state, { query }) => {
    state.query = query;
    state.loading.list = true;
  }),
  immerOn(LocationActions.load_Locations_Success, (state, { locations }) => {
    state.locations = locations;
    state.loading.list = false;
  }),
  immerOn(
    LocationActions.load_Active_Locations_Success,
    (state, { locations }) => {
      state.locations = locations;
      state.loading.list = false;
    }
  ),
  immerOn(LocationActions.load_timezones_Success, (state, { timezones }) => {
    state.timezones = timezones;
    state.loading.list = false;
  }),
  immerOn(
    LocationActions.load_Locations_Failure,
    LocationActions.load_timezones_Failure,
    LocationActions.remove_Location_Success,
    LocationActions.remove_Location_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    LocationActions.add_Location,
    LocationActions.edit_Location,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    LocationActions.add_Location_Success,
    LocationActions.add_Location_Failure,
    LocationActions.edit_Location_Success,
    LocationActions.edit_Location_Failure,
    (state) => {
      state.loading.createEdit = false;
      state.isVisibleDialog = false;
      state.isEdit = false;
      state.location = null;
    }
  ),
  immerOn(
    LocationActions.is_Site_District_Dialog_Visible,
    (state, { isVisible, location }) => {
      state.isSiteDistrictDialog = isVisible;
      state.location = location;
    }
  ),
  immerOn(
    LocationActions.is_Add_Edit_Dialog_Visible,
    (state, { isVisible, isEdit, location }) => {
      state.isVisibleDialog = isVisible;
      state.isEdit = isEdit;
      state.location = location;
    }
  )
);

export const locationsFeature = createFeature({
  name: 'locations',
  reducer: locationsReducer,
  extraSelectors: ({ selectLoading, selectSiteList }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
    selectFilteredSite: createSelector(selectSiteList, (selectSiteList) =>
      selectSiteList.filter((item) => item)
    ),
  }),
});
