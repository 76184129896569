import {createFeature, createReducer, createSelector} from "@ngrx/store";
import {immerOn} from "ngrx-immer/store";
import {SiteActions} from "../actions/site.actions";
import {locationsFeature} from "./locations.features";
import {siteInitialState} from "../states/site.state";

export const siteReducer = createReducer(
  siteInitialState,
  immerOn(
    SiteActions.remove_Site,
    SiteActions.load_Sites_By_Location_Id,
    SiteActions.load_Sites,
    (state) => {
      state.loading.list = true;
    }
  ),
  immerOn(
    SiteActions.set_Site_Id,
    (state, { id }) => {
      state.siteId = id;
    }
  ),
  immerOn(
    SiteActions.load_Sites_By_Location_Id_Success,
    (state, { sites }) => {
      state.sites = sites;
      state.loading.list = false;
    }
  ),
  immerOn(
    SiteActions.load_Sites_Success,
    (state, { sites }) => {
      state.sites = sites;
      state.loading.list = false;
    }
  ),
  immerOn(
    SiteActions.load_Sites_Failure,
    SiteActions.load_Sites_By_Location_Id_Failure,
    SiteActions.remove_Site_Success,
    SiteActions.remove_Site_Failure,
    (state) => {
      state.loading.list = false;
    }
  ),
  immerOn(
    SiteActions.add_Site,
    SiteActions.edit_Site,
    (state) => {
      state.loading.createEdit = true;
    }
  ),
  immerOn(
    SiteActions.add_Site_Success,
    SiteActions.add_Site_Failure,
    SiteActions.edit_Site_Success,
    SiteActions.edit_Site_Failure,
    SiteActions.load_Area_List_Failure,
    SiteActions.load_Area_List_Success,
    (state) => {
      state.loading.createEdit = false;
    }
  )
);

export const siteFeature = createFeature({
  name: 'site',
  reducer: siteReducer,
  extraSelectors: ({ selectLoading, selectSites, selectAreaList }) => ({
    selectListLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.list
    ),
    selectCreateEditLoader: createSelector(
      selectLoading,
      (selectLoading) => selectLoading.createEdit
    ),
    selectSites: createSelector(
      selectSites,
      (sites) => sites
    ),
    selectFilteredArea: createSelector(
      selectAreaList,
      (selectAreaList) =>
        selectAreaList.filter(
          (item) => item
        )
    ),
    selectLocation: (locations: string[]) =>
      createSelector(locationsFeature.selectLocations, (location) =>
        location.filter((item) => locations.includes(item.name))),
  }),
});
