<p-dialog [modal]="true" [(visible)]="dialogVisible" (onHide)="hideDialog()" [style.min-width.px]="1000">
  <ng-template pTemplate="header">
    <div class="header">Add vendor</div>
  </ng-template>

  <ng-template pTemplate="content" [formGroup]="form">
    <div class="d-flex gap-20">
      <div class="flex-1 d-flex flex-direction-column gap-20">
        <div class="d-flex flex-direction-column gap-8 mb-12">
          <label class="fs-14">Name</label>
          <input formControlName="vendorName" placeholder="Vendor name" required type="text" pInputText />
          <!-- this is a placeholder to ensure correct spacing -->
          <small class="validation-control-error" style="visibility: hidden;"></small>
        </div>

        <div formArrayName="vendorWarehouses">
          <div *ngFor="let warehouse of warehousesCtrl.controls; let i = index" [formGroupName]="i">
            <div class="d-flex gap-20 mb-12">
              <div class="flex-1 d-flex flex-direction-column gap-8">
                <label class="fs-14">Warehouse name</label>
                <input placeholder="Name" formControlName="name" pInputText maxlength="100" />
                <small
                  class="validation-control-error"
                  *ngIf="
                    warehouse.get('name')?.invalid &&
                    (warehouse.get('name')?.touched || warehouse.get('name')?.dirty)
                  "
                >Warehouse name is required
                </small>
                <!-- this is a placeholder to ensure correct spacing -->
                <small class="validation-control-error" *ngIf="!(warehouse.get('name')?.invalid &&
                  (warehouse.get('name')?.touched || warehouse.get('name')?.dirty))" style="visibility: hidden;"></small>
              </div>

              <div class="flex-1 d-flex flex-direction-column gap-8">
                <label class="fs-14">District</label>
                <p-dropdown
                  [options]="districtList()"
                  formControlName="districtId"
                  optionValue="districtId"
                  optionLabel="districtName"
                  [filter]="true"
                  filterBy="districtName"
                  placeholder="Select"
                  styleClass="new-version"
                  panelStyleClass="new-version-panel"
                  appendTo="body"
                >
                  <ng-template let-district pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <div>{{ district.districtName }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <small
                  class="validation-control-error"
                  *ngIf="
                    warehouse.get('districtId')?.invalid &&
                    (warehouse.get('districtId')?.touched || warehouse.get('districtId')?.dirty)
                  "
                >District is required
                </small>
                <!-- this is a placeholder to ensure correct spacing -->
                <small class="validation-control-error" *ngIf="!(warehouse.get('districtId')?.invalid &&
                  (warehouse.get('districtId')?.touched || warehouse.get('districtId')?.dirty))" style="visibility: hidden;"></small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-1 d-flex flex-direction-column gap-20">
        <div class="d-flex flex-direction-column gap-8 mb-12">
          <label class="fs-14">Location</label>
          <p-dropdown
            [options]="locations()"
            formControlName="locationId"
            optionValue="locationId"
            optionLabel="name"
            [filter]="true"
            filterBy="name"
            placeholder="Select"
            styleClass="new-version"
            panelStyleClass="new-version-panel"
            appendTo="body"
            (onChange)="onLocationChange($event.value)"
          >
            <ng-template let-location pTemplate="item">
              <div class="flex align-items-center gap-2">
                <div>{{ location.name }}</div>
              </div>
            </ng-template>
          </p-dropdown>
          <small
            class="validation-control-error"
            *ngIf="
            form.controls.locationId!.invalid &&
            (form.controls.locationId!.touched || form.controls.locationId!.dirty)
          "
          >Location is required
          </small>
          <!-- this is a placeholder to ensure correct spacing -->
          <small class="validation-control-error" *ngIf="!(form.controls.locationId!.invalid &&
            (form.controls.locationId!.touched || form.controls.locationId!.dirty))" style="visibility: hidden;"></small>
        </div>

        <div formArrayName="vendorWarehouses">
          <div *ngFor="let warehouse of warehousesCtrl.controls; let i = index" [formGroupName]="i">
            <div class="d-flex flex-direction-column gap-8 mb-12">
              <label class="fs-14">Address</label>
              <input placeholder="Address" formControlName="address" pInputText  maxlength="100"/>
              <small
                class="validation-control-error"
                *ngIf="
                warehouse.get('address')?.invalid &&
                (warehouse.get('address')?.touched || warehouse.get('address')?.dirty)
              "
              >
                Address is required
              </small>
              <!-- this is a placeholder to ensure correct spacing -->
              <small class="validation-control-error" *ngIf="!(warehouse.get('address')?.invalid &&
                (warehouse.get('address')?.touched || warehouse.get('address')?.dirty))" style="visibility: hidden;"></small>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-1 d-flex flex-direction-column gap-20">
        <div class="d-flex flex-direction-column gap-8 mb-12">
          <label class="fs-14">Country</label>
          <input formControlName="country" placeholder="Country" pInputText />
          <!-- this is a placeholder to ensure correct spacing -->
          <small class="validation-control-error" style="visibility: hidden;"></small>
        </div>

        <div formArrayName="vendorWarehouses">
          <div *ngFor="let warehouse of warehousesCtrl.controls; let i = index" [formGroupName]="i">
            <div class="d-flex gap-20 mb-12">
              <div class="flex-1 d-flex flex-direction-column gap-8 ">
                <label class="fs-14">Postal Code</label>
                <input placeholder="Postal code" formControlName="postCode" pInputText maxlength="100" />
                <small
                  class="validation-control-error"
                  *ngIf="
                    warehouse.get('postCode')?.invalid &&
                    (warehouse.get('postCode')?.touched || warehouse.get('postCode')?.dirty)
                  "
                >Postal code is required
                </small>
                <!-- this is a placeholder to ensure correct spacing -->
                <small class="validation-control-error" *ngIf="!(warehouse.get('postCode')?.invalid &&
                  (warehouse.get('postCode')?.touched || warehouse.get('postCode')?.dirty))" style="visibility: hidden;"></small>
              </div>

              <div class="flex-1 d-flex flex-direction-column gap-8">
                <label class="fs-14">City</label>
                <input placeholder="City" formControlName="city" pInputText maxlength="100" />
                <small
                  class="validation-control-error"
                  *ngIf="
                    warehouse.get('city')?.invalid &&
                    (warehouse.get('city')?.touched || warehouse.get('city')?.dirty)
                  "
                >City is required
                </small>
                <!-- this is a placeholder to ensure correct spacing -->
                <small class="validation-control-error" *ngIf="!(warehouse.get('city')?.invalid &&
                  (warehouse.get('city')?.touched || warehouse.get('city')?.dirty))" style="visibility: hidden;"></small>
              </div>
              <div class="d-flex flex-direction-column gap-8">
                <label style="opacity: 0">.</label>
                <i *ngIf="warehousesCtrl.length > 1"
                   class="pi pi-trash cursor-pointer mt-6"
                   style="color: red;"
                   (click)="removeWarehouse(i)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-16">
      <button *ngIf="warehousesCtrl.length < 10" [disabled]="isAddWarehouseDisabled" class="btn-white" (click)="addWarehouse()">Add warehouse</button>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button class="btn-white" (click)="hideDialog()">Cancel</button>
    <button class="button-primary" (click)="saveVendor()">{{ isAdd ? 'Add' : 'Save' }}</button>
  </ng-template>
</p-dialog>
