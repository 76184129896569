import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
  standalone: true,
})
export class FilterByPipe implements PipeTransform {
  transform<T>(array: T[], filterBy: (item: T) => boolean): T[] {
    return array?.filter(filterBy);
  }
}
