import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DistanceService } from '../../distance.service';
import { DistanceActions } from '../actions/distance.actions';
import {
  Distance,
  DistancePageTableData,
} from '../../interfaces/distance.interface';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { DistanceAddEditComponent } from '../../../../../../../../apps/allocate/src/app/maintenance/shared/components/distance-add-edit/distance-add-edit.component';
import { Store } from '@ngrx/store';
import { distancesFeature } from '../features/distance.features';
import { DialogOptions } from '../../../config/dialog-options';
import { AssetActions } from '../actions/assets.actions';
import { FileService } from '../../../file.service';

export const loadDistancePage = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(DistanceActions.load_Distance_Page),
      switchMap(() =>
        of(DistanceActions.load_Distances(), AssetActions.load_Assets())
      )
    );
  },
  {
    functional: true,
  }
);

export const loadDistances = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    distanceService = inject(DistanceService)
  ) => {
    return actions.pipe(
      ofType(
        DistanceActions.load_Distances,
        DistanceActions.update_Distance_Queries,
        DistanceActions.remove_Distance_Success,
        DistanceActions.add_Distance_Success,
        DistanceActions.edit_Distance_Success
      ),
      concatLatestFrom(() => store.select(distancesFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap((action) =>
        distanceService.loadDistances(action).pipe(
          map((distances: DistancePageTableData) => {
            return DistanceActions.load_Distances_Success({ distances });
          }),
          catchError((error: HttpErrorResponse) =>
            of(DistanceActions.load_Distances_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeDistance = createEffect(
  (actions = inject(Actions), distanceService = inject(DistanceService)) => {
    return actions.pipe(
      ofType(DistanceActions.remove_Distance),
      mergeMap((action) =>
        distanceService.removeDistance(action.id).pipe(
          map((res: Distance) =>
            DistanceActions.remove_Distance_Success({
              distance: res,
              successMessage: 'Distanced remove successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(DistanceActions.remove_Distance_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addDistance = createEffect(
  (actions = inject(Actions), distanceService = inject(DistanceService)) => {
    return actions.pipe(
      ofType(DistanceActions.add_Distance),
      mergeMap((action) =>
        distanceService.addDistance(action.distance).pipe(
          map((res: Distance) =>
            DistanceActions.add_Distance_Success({
              distance: res,
              successMessage: 'Distance added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(DistanceActions.add_Distance_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editDistance = createEffect(
  (actions = inject(Actions), distanceService = inject(DistanceService)) => {
    return actions.pipe(
      ofType(DistanceActions.edit_Distance),
      mergeMap((action) =>
        distanceService.editDistance(action.distanceId, action.distance).pipe(
          map((res: Distance) =>
            DistanceActions.edit_Distance_Success({
              distance: res,
              successMessage: 'Distance edited successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(DistanceActions.edit_Distance_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const openDistanceDialog = createEffect(
  (
    actions = inject(Actions),
    dialog = inject(MatDialog),
    getDialogOptions = inject(DialogOptions)
  ) => {
    return actions.pipe(
      ofType(DistanceActions.open_Distance_Dialog),
      tap((action) => {
        dialog.open(DistanceAddEditComponent, {
          ...getDialogOptions,
          data: {
            distance: action.distance,
          },
        });
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

export const exportDistances = createEffect(
  (
    actions = inject(Actions),
    distanceService = inject(DistanceService),
    fileService = inject(FileService)
  ) => {
    return actions.pipe(
      ofType(DistanceActions.export_Distances),
      mergeMap(() =>
        distanceService.exportDistances().pipe(
          map((res: ArrayBuffer) => {
            fileService.downloadFile(res, 'Distances');
            return DistanceActions.export_Distances_Success({
              successMessage: 'Exported Distances Successfully!',
            });
          }),
          catchError((error: HttpErrorResponse) =>
            of(DistanceActions.export_Distances_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);
