import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, mergeMap, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationActions } from '../actions/locations.actions';
import { Location } from '../../interfaces/location.interface';
import { Store } from '@ngrx/store';
import { locationsFeature } from '../features';
import { switchMap, tap } from 'rxjs/operators';
import { LocationsService } from '../../location.service';
import { TimezoneInfo } from '../../interfaces/timezone-info.interface';

export const loadLocationPage = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(LocationActions.load_Location_Page),
      switchMap(() =>
        of(LocationActions.load_Locations(), LocationActions.load_timezones())
      )
    );
  },
  {
    functional: true,
  }
);

export const loadLocations = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    locationsService = inject(LocationsService)
  ) => {
    return actions.pipe(
      ofType(
        LocationActions.load_Locations,
        LocationActions.update_Location_Queries,
        LocationActions.remove_Location_Success,
        LocationActions.add_Location_Success,
        LocationActions.edit_Location_Success
      ),
      concatLatestFrom(() => store.select(locationsFeature.selectQuery)),
      map(([, res]) => res),
      mergeMap(() =>
        locationsService.loadLocations().pipe(
          map((res: Location[]) =>
            LocationActions.load_Locations_Success({ locations: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LocationActions.load_Locations_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadActiveLocations = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    locationsService = inject(LocationsService)
  ) => {
    return actions.pipe(
      ofType(LocationActions.load_Active_Locations),
      switchMap(() =>
        locationsService.loadActiveLocations().pipe(
          map((res: Location[]) =>
            LocationActions.load_Active_Locations_Success({ locations: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LocationActions.load_Active_Locations_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const removeLocation = createEffect(
  (actions = inject(Actions), locationsService = inject(LocationsService)) => {
    return actions.pipe(
      ofType(LocationActions.remove_Location),
      mergeMap((action) =>
        locationsService.removeLocation(action.id).pipe(
          map((res: Location) =>
            LocationActions.remove_Location_Success({
              location: res,
              successMessage: 'Location removed successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LocationActions.remove_Location_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const addLocation = createEffect(
  (actions = inject(Actions), locationsService = inject(LocationsService)) => {
    return actions.pipe(
      ofType(LocationActions.add_Location),
      mergeMap((action) =>
        locationsService.addLocation(action.location).pipe(
          map((res: Location) =>
            LocationActions.add_Location_Success({
              location: res,
              successMessage: 'Location added successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LocationActions.add_Location_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const editLocation = createEffect(
  (actions = inject(Actions), locationsService = inject(LocationsService)) => {
    return actions.pipe(
      ofType(LocationActions.edit_Location),
      mergeMap((action) =>
        locationsService.editLocation(action.locationId, action.location).pipe(
          map((res: Location) =>
            LocationActions.edit_Location_Success({
              location: res,
              successMessage: 'Location edited successfully!',
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LocationActions.edit_Location_Failure({ error: error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);

export const loadTimezones = createEffect(
  (actions = inject(Actions), locationsService = inject(LocationsService)) => {
    return actions.pipe(
      ofType(LocationActions.load_timezones),
      mergeMap(() =>
        locationsService.loadTimezones().pipe(
          map((res: TimezoneInfo[]) =>
            LocationActions.load_timezones_Success({ timezones: res })
          ),
          catchError((error: HttpErrorResponse) =>
            of(LocationActions.load_timezones_Failure({ error }))
          )
        )
      )
    );
  },
  {
    functional: true,
  }
);
