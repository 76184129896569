import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'arrayIncludes',
  pure: true, // Ensures performance by not triggering unnecessary recalculations
})
export class ArrayIncludesPipe implements PipeTransform {
  transform<T>(array: T[], value: T): boolean {
    if (!Array.isArray(array)) {
      return false;
    }
    return array.includes(value);
  }
}
