import { LocationState } from '../../interfaces/location-state.interface';
import { maintenanceInitialState } from './maintenance.state';

export const locationsInitialState: LocationState = {
  locations: [],
  locationId: '',
  location: null,
  siteList: [],
  districtList: [],
  site: '',
  district: '',
  timezones: [],
  isEdit: false,
  isVisibleDialog: false,
  isSiteDistrictDialog: false,
  ...maintenanceInitialState,
};
